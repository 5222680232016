<template>
  <div>
    <modal-image-viewer
      :id="custome_id"
      :image-list="previewImage"
      :selected_file="selected_file"
    ></modal-image-viewer>

    <div class="full-input-group">
      <b-form-group
        :label="`上傳${title ? title : ''}文件：`"
        label-class="full-label"
        label-for="upload"
        :class="required ? 'required' : ''"
      >
        <div id="file-drag-drop">
          <div class="fileform" :ref="`fileform${custome_id}`">
            <div
              v-show="!previewImage > 0 || previewImage.length == 0"
              class="mt-2 pt-50"
            >
              <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" />
              <p class="colorBlack mt-1 mb-50">選擇檔案或將檔案拖放於此</p>
              <p class="mb-50">只能選擇PDF檔或圖片類型的檔案</p>
              <p class="mb-2">Each file size no more than 2MB</p>
              <a
                href="javascript:void(0)"
                class="file-button bgColor1"
                @click="
                  $refs[`pickFile${custome_id}`].$el.childNodes[0].click()
                "
                >選擇檔案</a
              >

              <b-form-file
                v-model="image_files"
                style="display: none"
                @input="getImagePreviews"
                :ref="`pickFile${custome_id}`"
                multiple
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop one file here..."
              ></b-form-file>
            </div>

            <div
              class="file-listing"
              v-show="previewImage && previewImage.length > 0"
            >

              <a
                href="javascript:void(0)"
                class="removeAllButton d-flex align-items-center"
                @click="removeAll()"
                >
                <feather-icon size="18" class="mr-25" icon="Trash2Icon" />
                按此取消全部新添加的檔案</a
              >
              <!-- <p class="mb-50">只能選擇PDF檔或圖片類型的檔案</p> -->
              <div
                class="d-flex justify-content-center mb-2 align-items-center"
              >
                <h4 class="mb-0 color1">新增檔案</h4>
                <b-button
                  @click="
                    $refs[`pickFile${custome_id}`].$el.childNodes[0].click()
                  "
                  class="ml-1 round-100 bgColor1 p-50"
                >
                  <feather-icon icon="PlusIcon" size="16" />
                </b-button>
              </div>
              <!--// if image uploaded show -->

              <div class="preview-list">
                <div
                  class="d-flex preview-list-item justify-content-center align-items-center mb-1"
                  v-for="(file, index) in previewImage"
                  :key="`file-listing${index}`"
                >
                  <div
                    :class="`${
                      file.is_exist ? 'exist-item' : ''
                    } d-flex justify-content-between align-items-center`"
                  >
                    <div class="preview-img-wrapper">
                      <embed
                        v-if="file.type == 'application/pdf'"
                        :src="file.src"
                        class="pdf"
                        type="application/pdf"
                      />
                      <img v-else class="preview mr-1" :src="file.src" />
                    </div>
                    <!-- <span class="text">{{ file.name }}。</span> -->
                    <div class="d-flex">
                      <a
                        class="color1"
                        href="javascript:void(0)"
                        @click="beforeSubmitPreview(index)"
                        >preview</a
                      >

                      <a
                        class="remove ml-2"
                        v-on:click="removeFile(index, file)"
                      >
                        <feather-icon size="18" class="" icon="Trash2Icon" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="redColor mt-50" v-if="required && previewImage.length == 0">
          請上傳付費文件！
        </p>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BFormFile,
  BOverlay,
} from "bootstrap-vue";
import { ref, watch } from "@vue/composition-api";
import draggable from "vuedraggable";
import ModalImageViewer from "@/layouts/components/file/ModalImageViewer.vue";
// import ModalPdf from "@/layouts/components/file/ModalPdf.vue-bak";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BFormFile,
    draggable,
    BOverlay,
    // ModalPdf,
    ModalImageViewer,
  },
  data() {
    return {
      image_files: [],
      dragAndDropCapable: false,
      selected_file: null,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    props_previewImage: {
      type: Array,
    },
    custome_id: {
      type: String,
    },
    required: {
      type: Boolean,
    },
  },
  methods: {
    determineDragAndDrop() {
      var div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },
    getImagePreviews() {
      // check image_files is empty array


      this.image_files.forEach((file_ele, index) => {
        // check size > 2MB
        if (file_ele.size >= 1024 * 1024 * 2) {
          this.$bvToast.toast(`Please drop a file size < 2 MB file!`, {
            title: "Error",
            autoHideDelay: 2500,
            variant: "danger",
          });
          this.image_files.splice(index, 1);
        } else {
          let file = file_ele;
          if (
            file.type ==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            this.previewImage.push({
              src: process.env.VUE_APP_IMAGE_URL + "/images/icon/word.png",
              type: file.type,
            });

          } else if (
            file.type ==
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            file.type == "application/vnd.ms-excel"
          ) {
            // excel file ".xlsx" and ".xls"

            this.previewImage.push({
              src: process.env.VUE_APP_IMAGE_URL + "/images/icon/excel.png",
              type: file.type,
            });
            
          } else if (file.type == "application/pdf") {
            const fileURL = URL.createObjectURL(file);

            this.previewImage.push({
              src: fileURL,
              type: file.type,
            });
            
          } else if (
            file.type == "image/jpeg" ||
            file.type == "image/jpg" ||
            file.type == "image/png" ||
            file.type == "image/gif" ||
            file.type == "image/webp" ||
            file.type == "image/svg+xml"
          ) {
            const type = file.type;
            /*
                Create a new FileReader object
              */
            let reader = new FileReader();
            /*
                Add an event listener for when the file has been loaded
                to update the src on the file preview.
              */
            reader.addEventListener(
              "load",
              function () {
                this.previewImage.push({
                  src: reader.result,
                  type: type,
                });
                
              }.bind(this),
              false
            );

            /*
                Read the data for the file in through the reader. When it has
                been loaded, we listen to the event propagated and set the image
                src to what was loaded from the reader.
              */
            reader.readAsDataURL(file);
          } else if (
            file.type != "image/jpeg" &&
            file.type != "image/jpg" &&
            file.type != "image/png" &&
            file.type != "image/gif" &&
            file.type != "image/webp" &&
            file.type != "image/svg+xml"
          ) {
            // files other than images

            this.previewImage.push({
              src: process.env.VUE_APP_IMAGE_URL + "/images/icon/others.jpeg",
              type: file.type,
            });
            
          }
        }

        if (index === this.image_files.length - 1) {
          this.$emit("file-uploaded", this.image_files);
        }
      });
    },
    removeAll() {
      this.image_files = [];
      this.previewImage = this.previewImage.filter((ele) => ele.is_exist);
      this.$emit("file-uploaded", this.image_files);
    },
    removeFile(index, file) {
      if (file.is_exist) {
        this.$emit("file-delete", file.id);
      } else {
        this.image_files.splice(index, 1);
        this.previewImage.splice(index, 1);
        this.$emit("file-uploaded", this.image_files);
      }
    },
    beforeSubmitPreview(index) {
      this.selected_file = index;
      this.$bvModal.show(`modal-image-viewer${this.custome_id}`);
    },
  },
  computed: {
    // convertSize() {
    //   var size;
    //   if (this.image_files) {
    //     size = this.image_files.size;
    //     const calc = size / (1024 * 1024);
    //     return calc > 0.1
    //       ? calc.toFixed(2) + "MB"
    //       : (calc * 1024).toFixed(2) + "KB";
    //   } else {
    //     return 0 + "KB";
    //   }
    // },
  },
  mounted() {
    /*
      Determine if drag and drop functionality is capable in the browser
    */
    this.dragAndDropCapable = this.determineDragAndDrop();
    /*
      If drag and drop capable, then we continue to bind events to our elements.
    */
    /*
        Listen to all of the drag events and bind an event listener to each
        for the fileform.
      */

    [
      "drag",
      "dragstart",
      "dragend",
      "dragover",
      "dragenter",
      "dragleave",
      "drop",
    ].forEach(
      function (evt) {
        /*
              For each event add an event listener that prevents the default action
              (opening the file in the browser) and stop the propagation of the event (so
              no other elements open the file in the browser)
          */
        this.$refs[`fileform${this.custome_id}`].addEventListener(
          evt,
          function (e) {
            e.preventDefault();
            e.stopPropagation();
          }.bind(this),
          false
        );
      }.bind(this)
    );

    /*
          Add an event listener for drop to the form
        */
    this.$refs[`fileform${this.custome_id}`].addEventListener(
      "drop",
      function (e) {
        /*
            Capture the files from the drop event and add them to our local files
            array.
          */
        // if (e.dataTransfer.files.length > 1) {
        //   this.$bvToast.toast(`Please drop only one file!`, {
        //     title: "Error",
        //     autoHideDelay: 2500,
        //     variant: "danger",
        //   });
        //   e.dataTransfer.files = [];
        //   this.image_files = null;
        // } else

        e.dataTransfer.files.forEach((ele, index) => {
          if (ele.size >= 1024 * 1024 * 2) {
            this.$bvToast.toast(`Please drop a file size < 2 MB file!`, {
              title: "Error",
              autoHideDelay: 2500,
              variant: "danger",
            });
          } else {
            // image files = the dropped files
            this.image_files.push(ele);
          }

          if (index === e.dataTransfer.files.length - 1) {
            this.getImagePreviews();
          }
        });
      }.bind(this)
    );
  },
  setup(props) {
    const previewImage = ref([]);
    watch(
      () => props.props_previewImage,
      (newVal) => {
        if (props.props_previewImage && props.props_previewImage.length > 0) {
          previewImage.value = [
            ...newVal.map((ele) => {
              return {
                id: ele.id,
                src: ele.image_path,
                type:
                  ele.original_image_path.indexOf("pdf") > -1
                    ? "application/pdf"
                    : "image/jpeg",
                is_exist: 1,
              };
            }),
          ];
        } else {
          previewImage.value = [];
        }
      },
      { immediate: true }
    );

    return {
      previewImage,
    };
  },
};
</script>

<style lang="scss">
.fileform {
  position: relative;
}
.removeAllButton {
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: red;
}
.preview-list {
  display: block;
  margin: 0 auto;
  .preview-list-item {
    .preview-img-wrapper {
      width: 250px;
      margin-left: 5rem;
      .pdf{
        width: 80%;
      }
    }

    div.exist-item {
      position: relative;
      &:before {
        content: "已有文件";
        left: -10px;
        position: absolute;
        color: #0039ac;
      }
    }
  }
}
</style>
