<template>
  <b-modal
    id="modal-print-receipt"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    scrollable
    title=""
    title-class="font-weight-bolder"
    body-class="common_modal modal_visible"
    hide-header
    hide-footer
    size="lg"
    hide-header-close
  >
    <div style="">
      <h3 class="mt-1 mb-2 d-flex justify-content-center align-items-center">
        <font-awesome-icon class="mr-50" icon="fa-solid fa-filter" />
        列印收據
      </h3>
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <b-form class="mt-1" @submit.prevent="onSubmit">
          <b-row>
            <b-col class="mb-1" cols="12">
              <b-form-group label="收據：" label-for="print_item" label-cols-md="1">
                <v-select
                  id="print_item"
                  v-model="print_item"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="print_list"
                  label="original_image_path"
                  :reduce="(option) => option"
                  placeholder="選擇收據"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col class="print-item mb-1" cols="12">
              <div v-if="print_item">
                <embed
                  v-if="print_item.original_image_path.indexOf('pdf') > -1"
                  id="pdfcontainer"
                  class="pdf"
                  :src="print_item.image_path"
                  type="application/pdf"
                  />

                <img
                  v-else
                  class="d-block image"
                  :src="print_item.image_path"
                  alt="image slot"
                />
              </div>
              <div v-else class="empty-receipt-list">未找到匹配的記錄</div>
            </b-col>
          </b-row>

          <div class="d-flex mt-3 align-items-center justify-content-center">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              class="mr-2"
              @click="hide"
            >
              <feather-icon size="16" class="mr-50" icon="XIcon" />
              取消
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
            >
              <feather-icon size="16" class="mr-50" icon="CheckCircleIcon" />
              確認
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ref, watch } from "@vue/composition-api";
import store from "@/store";
import printJS from 'print-js'

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCardBody,
    BCard,
    BFormInvalidFeedback,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-print-receipt");
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          if (
            this.print_item &&
            (
              this.print_item.original_image_path.indexOf("jpeg") > -1 ||
              this.print_item.original_image_path.indexOf("jpg") > -1 ||
              this.print_item.original_image_path.indexOf("png") > -1 ||
              this.print_item.original_image_path.indexOf("gif") > -1 ||
              this.print_item.original_image_path.indexOf("webp") > -1 ||
              this.print_item.original_image_path.indexOf("svg") > -1
            )
          ) {
            this.loading = false;
            this.PrintImage(this.print_item.image_path);
          }

          if (
            this.print_item &&
            this.print_item.original_image_path.indexOf("pdf") > -1
          ) {
            this.loading = false;
            printJS(this.print_item.image_path)
          }
        }
      });
    },
    ImageToPrint(source) {
      return (
        "<html><head><scri"+"pt>function step1(){\n" +
        "setTimeout('step2()', 10);}\n" +
        "function step2(){window.print();window.close()}\n" +
        "</scri" + "pt></head><body onload='step1()'>\n" +
        "<img src='" + source + "' /></body></html>"
      );
    },
    PrintImage(source) {
      var Pagelink = "about:blank";
      var pwa = window.open(Pagelink, "_new");
      pwa.document.open();
      pwa.document.write(this.ImageToPrint(source));
      pwa.document.close();
    },
  },
  props: {
    print_list: {},
  },
  setup(props) {
    const print_item = ref(null) 
    watch(
      () => props.print_list,
      (newVal) => {
        print_item.value = null
      }
    )

    return {
      print_item
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.empty-receipt-list {
  text-align: center;
  padding: 20px 0 0;
}

#modal-print-receipt {
  .modal-dialog {
    max-width: 1200px;
  }
  .modal-content {
    width: 100%;
    .modal-body {
      width: 100%;
    }
  }
}

.print-item {
  max-width: 1000px;
  width: 100vw;
  height: auto;
  aspect-ratio: 16/9;
  margin: 0 auto 50px;
  padding: 0 2rem;
  > div {
    height: 100%;
  }
  img {
    max-width: 100%;
  }
  .image {
    height: 100%;
    display: block;
    margin: 0 auto;
    border: 1px solid #8f8f8f;
  }
  .pdf {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    border: 1px solid #8f8f8f;
  }
}
</style>
