<template>
  <b-modal
    id="modal-early-repayment"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    scrollable
    title=""
    title-class="font-weight-bolder"
    body-class="common_modal modal_visible"
    hide-header
    hide-footer
    no-close-on-backdrop
    size="lg"
    @show="resetModal"
  >
    <div>
      <h3 class="mt-1 mb-2 d-flex justify-content-center align-items-center">
        <font-awesome-icon class="mr-50" icon="fa-solid fa-money-bill" />
        提早還清
      </h3>
      <font-awesome-icon
        @click="hide"
        class="close-button"
        style="font-size: 25px"
        icon="fa-solid fa-circle-xmark"
      />
      <b-alert variant="danger" :show="show">
        <div class="alert-body">
          <span
            ><strong>{{ new_early_repayment.message }}</strong></span
          >
        </div>
      </b-alert>
      <hr class="w-100" />
      <div v-if="!new_early_repayment.late_payment">
      <div class="d-flex justify-content-between align-items-center mb-1 px-1">
        <span class="">
          <b>清數日期:</b>
          <span>{{
            new_early_repayment.payment_date &&
            new_early_repayment.payment_date.slice(0, 10)
          }}</span>
        </span>
        <span class="">
          <b>上期到期日:</b>
          <span>{{
            new_early_repayment.last_payment_date &&
            new_early_repayment.last_payment_date.slice(0, 10)
          }}</span>
        </span>
        <span class="ml-3">
          <b>提早供款截息日數:</b>
          <span>{{ new_early_repayment.date_diff }}日</span>
        </span>
      </div>
      <div class="d-flex justify-content-between align-items-center mb-1 px-1">
        <span>
          <b>初期本金：</b>
          <span>{{ new_early_repayment.remain_amount }}</span>
        </span>
        <span class="ml-3" v-if="new_early_repayment.total_extra_period">
          <b>提早還款期數：</b>
          <span>{{ new_early_repayment.total_extra_period }}</span>
        </span>
        <span class="ml-3">
          <b>該期利息：</b>
          <span>{{ new_early_repayment.original_interest }}</span>
        </span>
      </div>

      <div class="d-flex justify-content-between align-items-center mb-1 px-1">
        <div class="d-flex">
          <span class="">
            <b>提早還清利息：</b>
            <span
              >該期利息/{{
                new_early_repayment.is_monthly ? 30 : 15
              }}*(提早供款截息日數) ＝ ({{
                new_early_repayment.original_interest
              }}/{{ new_early_repayment.is_monthly ? 30 : 15 }})*{{
                new_early_repayment.date_diff
              }}
              = {{ new_early_repayment.interest }} (截息日期:
              {{
                new_early_repayment.payment_date &&
                new_early_repayment.payment_date.slice(0, 10)
              }})</span
            >
          </span>
        </div>
      </div>
      <div
        class="d-flex justify-content-between align-items-center mb-1 px-1"
        v-if="new_early_repayment.remain_balance"
      >
        <div class="d-flex">
          <span class="">
            <b>遲交金額：</b>
            <span>{{ new_early_repayment.remain_balance }}</span>
          </span>
          <span class="ml-3">
            <b>請為該筆遲交金額輸入附加費</b>
            <span></span>
          </span>
        </div>
      </div>
      <div
        class="d-flex justify-content-between align-items-center mb-1 px-1"
        v-if="new_early_repayment.extra_paid"
      >
        <span class="">
          <b>提早還款餘額：</b>
          <span>{{ new_early_repayment.extra_paid }}</span>
        </span>
      </div>
      <hr class="w-100" />
      <div class="d-flex justify-content-between align-items-center mb-1 px-1">
        <span>
          <b
            >還款金額 = 初期本金 + 提早還清利息
            {{ new_early_repayment.remain_balance ? "+ 遲交金額" : ""
            }}{{ new_early_repayment.fee ? "+ 附加費" : ""
            }}{{ new_early_repayment.extra_paid ? "- 提早還款餘額" : "" }}</b
          >
        </span>
      </div>
      <div class="d-flex justify-content-between align-items-center mb-1 px-1">
        <span>
          <b
            >還款金額 = {{ new_early_repayment.remain_amount }} +
            {{ new_early_repayment.interest }}
            {{
              new_early_repayment.remain_balance
                ? "+ " + new_early_repayment.remain_balance
                : ""
            }}{{ new_early_repayment.fee ? "+ " + new_early_repayment.fee : ""
            }}{{
              new_early_repayment.extra_paid
                ? "- " + new_early_repayment.extra_paid
                : ""
            }}
            =
          </b>
          <span>{{
            Number(new_early_repayment.amount) +
            Number(new_early_repayment.fee || 0) -
            Number(new_early_repayment.extra_paid || 0)
          }}</span>
        </span>
      </div>
      </div>
      <!--避交提早還清-->
      <div v-else>
        <div class="d-flex justify-content-between align-items-center mb-1 px-1">
          <b class="text-danger">遲交提早還清</b>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-1 px-1">
        <span class="">
          <b>清數日期:</b>
          <span>{{
            new_early_repayment.payment_date &&
            new_early_repayment.payment_date.slice(0, 10)
          }}</span>
        </span>
        <span class="">
          <b>上期到期日:</b>
          <span>{{
            new_early_repayment.last_payment_date &&
            new_early_repayment.last_payment_date.slice(0, 10)
          }}</span>
        </span>
        <span class="ml-3">
          <b>提早供款截息日數:</b>
          <span>{{ new_early_repayment.date_diff }}日</span>
        </span>
      </div>
      <div class="d-flex justify-content-between align-items-center mb-1 px-1">
        <span>
          <b>初期本金：</b>
          <span>{{ new_early_repayment.remain_amount }}</span>
        </span>
        <span class="ml-3" v-if="new_early_repayment.total_extra_period">
          <b>提早還款期數：</b>
          <span>{{ new_early_repayment.total_extra_period }}</span>
        </span>
        <span class="ml-3">
          <b>該期利息：</b>
          <span>{{ new_early_repayment.original_interest }}</span>
        </span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center mb-1 px-1"
        v-if="new_early_repayment.late_payment_amount || new_early_repayment.late_payment_extra_amount"
      >
        <div class="d-flex">
          <span v-if="new_early_repayment.late_payment_amount">
            <b>已供：</b>
            <span>{{ new_early_repayment.late_payment_amount }}</span>
          </span>
          <span v-if="new_early_repayment.late_payment_extra_amount" :class="`${new_early_repayment.late_payment_amount?'mt-3':''}`">
            <b>已供款餘額：</b>
            <span>{{ new_early_repayment.late_payment_extra_amount }} (避交期數前供多了的款項)</span>
          </span>
        </div>
      </div>
      <div
        class="d-flex justify-content-between align-items-center mb-1 px-1"
        v-if="new_early_repayment.extra_paid"
      >
        <span class="">
          <b>提早還款餘額：</b>
          <span>{{ new_early_repayment.extra_paid }}</span>
        </span>
      </div>
      <hr class="w-100" />
      <div class="d-flex justify-content-between align-items-center mb-1 px-1">
        <span>
          <b
            >還款金額 = 初期本金 + 該期利息
            {{ new_early_repayment.late_payment_amount ? "- 已供" : ""
            }}{{ new_early_repayment.late_payment_extra_amount ? "- 已供款餘額：" : ""
            }}{{ new_early_repayment.fee ? "+ 附加費" : ""
            }}{{ new_early_repayment.extra_paid ? "- 提早還款餘額" : "" }}</b
          >
        </span>
      </div>
      <div class="d-flex justify-content-between align-items-center mb-1 px-1">
        <span>
          <b
            >還款金額 = {{ new_early_repayment.remain_amount }} +
            {{ new_early_repayment.interest }}
            {{
              new_early_repayment.late_payment_amount
                ? "- " + new_early_repayment.late_payment_amount
                : ""
            }}
            {{
              new_early_repayment.late_payment_extra_amount
                ? "- " + new_early_repayment.late_payment_extra_amount
                : ""
            }}
            {{ new_early_repayment.fee ? "+ " + new_early_repayment.fee : ""
            }}{{
              new_early_repayment.extra_paid
                ? "- " + new_early_repayment.extra_paid
                : ""
            }}
            =
          </b>
          <span>{{
            Number(new_early_repayment.amount) +
            Number(new_early_repayment.fee || 0) -
            Number(new_early_repayment.extra_paid || 0)
          }} (請自行輸入遲交附加費用)</span>
        </span>
      </div>


      </div>

      <hr class="w-100" />

      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-2" @submit.prevent="onSubmit">
          <b-row>
            <!-- receive_method -->
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="收款方式"
                rules="required"
              >
                <b-form-group
                  label="收款方式："
                  label-for="receive_method"
                  label-cols-md="3"
                  class="required"
                >
                  <v-select
                    v-if="receiveMethodOptions && receiveMethodOptions.length"
                    v-model="new_early_repayment.receive_method_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="receiveMethodOptions"
                    label="name"
                    :clearable="false"
                    :reduce="(option) => option.id"
                    placeholder="選擇收款方式"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- receive_account -->
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="收款帳號"
                :rules="
                  new_early_repayment.receive_method_id > 1 ? 'required' : ''
                "
              >
                <b-form-group
                  label="收款帳號："
                  label-for="receive_account_id"
                  label-cols-md="3"
                  :class="
                    new_early_repayment.receive_method_id > 1 ? 'required' : ''
                  "
                >
                  <v-select
                    v-model="new_early_repayment.receive_account_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :disabled="new_early_repayment.receive_method_id == 1"
                    :options="receiveAccountOptions"
                    label="name"
                    :clearable="false"
                    :reduce="(option) => option.id"
                    placeholder="選擇收款帳號"
                  >
                    <template #option="info">
                      {{ info.bank }} - {{ info.account_number }}
                    </template>
                    <template #selected-option="info">
                      {{ info.bank }} - {{ info.account_number }}
                    </template>
                  </v-select>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- receipt_number -->
            <b-col cols="12">
              <!-- <validation-provider
                #default="validationContext"
                name="收據編號"
                rules="required"
              > -->
              <b-form-group
                label="收據編號："
                label-for="receipt_number"
                label-cols-md="3"
              >
                <!-- class="required" -->
                <b-form-input
                  id="receipt_number"
                  disabled
                  v-model="new_early_repayment.receipt_number"
                  placeholder="系統自動生成收據編號"
                />
                <!-- :state="getValidationState(validationContext)" -->
                <!-- <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback> -->
              </b-form-group>
              <!-- </validation-provider> -->
            </b-col>

            <!-- receipt_date -->
            <b-col cols="12">
              <!-- <validation-provider
                #default="validationContext"
                name="收據日期 & 時間"
                rules="required"
              > -->
              <b-form-group
                label="收據日期 & 時間："
                label-for="receipt_date"
                label-cols-md="3"
              >
                <!-- class="required" -->
                <flat-pickr
                  v-model="new_early_repayment.receipt_date"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    altInput: true,
                    altFormat: 'Y-m-d H:i',
                    allowInput: true,
                    dateFormat: 'Y-m-d H:i',
                  }"
                  placeholder="輸入收據日期 & 時間"
                />
                <!-- <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback> -->
              </b-form-group>
              <!-- </validation-provider> -->
            </b-col>

            <!-- repayment_date -->
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="還款日期 & 時間"
                rules="required"
              >
                <b-form-group
                  label="還款日期 & 時間："
                  label-for="payment_date"
                  label-cols-md="3"
                  class="required"
                >
                  <flat-pickr
                    v-model="new_early_repayment.payment_date"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      altInput: true,
                      altFormat: 'Y-m-d H:i',
                      allowInput: true,
                      dateFormat: 'Y-m-d H:i',
                    }"
                    @input="changePaymentDate"
                    placeholder="輸入還款日期 & 時間"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="附加費："
                label-for="fee"
                label-cols-md="3"
                class="required"
              >
                <b-form-input
                  id="fee"
                  v-model="new_early_repayment.fee"
                  placeholder="輸入附加費"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="還款金額"
                rules="required"
              >
                <b-form-group
                  label="還款金額："
                  label-for="amount"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    id="amount"
                    :value="
                      Number(new_early_repayment.amount) +
                      Number(new_early_repayment.fee || 0) -
                      Number(new_early_repayment.extra_paid || 0)
                    "
                    :state="getValidationState(validationContext)"
                    placeholder="輸入還款金額"
                    disabled
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-form-group label="備註：" label-for="remark" label-cols-md="3">
                <b-form-input
                  id="remark"
                  v-model="new_early_repayment.remark"
                  placeholder="輸入備註"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12"> </b-col>

            <b-col cols="12" md="6">
              <!-- :required="true" -->
              <upload-file
                key="earlyFileupload1"
                title="付費"
                custome_id="7"
                :props_previewImage="new_early_repayment.payment_receipts"
                v-on:file-uploaded="onPayFileUploaded"
              ></upload-file>
            </b-col>
            <b-col cols="12" md="6">
              <upload-file
                key="earlyFileupload2"
                title="其他"
                custome_id="8"
                :props_previewImage="new_early_repayment.payment_documents"
                v-on:file-uploaded="onOtherFileUploaded"
              ></upload-file>
            </b-col>
          </b-row>

          <div class="d-flex mt-2 align-items-center justify-content-end">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              class="mr-2"
              @click="hide"
            >
              <feather-icon size="16" class="mr-50" icon="XIcon" />
              取消
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              :disabled="new_early_repayment.message != ''"
            >
              <feather-icon size="16" class="mr-50" icon="CheckCircleIcon" />
              確認
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </div>
    <!-- new_early_repayment -->
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormInvalidFeedback,
  BAlert,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { onMounted, ref, watch } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend,
} from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, email } from "@validations";
import axiosIns from "@/libs/axios";
// import ModalImageViewer from "@/layouts/components/file/ModalImageViewer.vue";
import uploadFile from "@/layouts/components/file/uploadFile.vue";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCardBody,
    BCard,
    BFormInvalidFeedback,
    // ModalImageViewer,
    uploadFile,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BAlert,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-early-repayment");
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let formData = new FormData();
          let data = this.new_early_repayment;
          // formData.append("id", data.id);
          formData.append("client_loan_id", data.client_loan_id);
          formData.append("receive_method_id", data.receive_method_id);
          if (data.receive_account_id)
            formData.append("receive_account_id", data.receive_account_id);

          if (data.receipt_number)
            formData.append("receipt_number", data.receipt_number);

          if (data.receipt_date)
            formData.append("receipt_date", data.receipt_date.substring(0, 16));

          formData.append("fee", data.fee);
          formData.append(
            "amount",
            Number(data.amount) +
              Number(data.fee || 0) -
              Number(data.extra_paid || 0)
          );
          formData.append("remark", data.remark ? data.remark : "");
          formData.append("payment_date", data.payment_date.substring(0, 16));

          if (data.payment_receipts && data.payment_receipts.length > 0) {
            data.payment_receipts.forEach((item) => {
              formData.append("payment_receipts[]", item);
            });
          }
          if (data.payment_documents && data.payment_documents.length > 0) {
            data.payment_documents.forEach((item) => {
              formData.append("payment_documents[]", item);
            });
          }

          this.loading = true;
          axiosIns
            .post("/contract/early_repayment/", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.hide();
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    onPayFileUploaded(file) {
      this.new_early_repayment.payment_receipts = file;
    },
    onOtherFileUploaded(file) {
      this.new_early_repayment.payment_documents = file;
    },
  },
  props: {
    receiveMethodOptions: {
      type: Array,
      required: true,
    },
    receiveAccountOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      previewImage: null,
      loading: false,
    };
  },
  setup(props) {
    const new_early_repayment = ref({});
    const resetEarlyRepayment = () => {
      new_early_repayment = JSON.parse(JSON.stringify({ id: 0 }));
    };
    const show = ref(false);

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetEarlyRepayment);

    const getEarlyRepaymentInfo = () => {
      store
        .dispatch("repayment/getEarlyRepaymentInfo", {
          id: router.currentRoute.params.contract_id,
          payment_date:
            new_early_repayment.value.payment_date.length > 10
              ? new_early_repayment.value.payment_date.slice(0, 10)
              : new_early_repayment.value.payment_date,
        })
        .then((response) => {
          show.value = false;
          new_early_repayment.value = {
            ...new_early_repayment.value,
            ...{
              remain_amount: response.data.actual_amount,
              remain_balance: response.data.remain_balance,
              amount: response.data.total_amount,
              interest: response.data.interest,
              total_extra_period: response.data.total_extra_period,
              extra_paid: response.data.extra_paid,
              last_payment_date: response.data.last_payment_date,
              is_monthly: response.data.is_monthly,
              date_diff: response.data.date_diff,
              original_interest: response.data.original_interest,
              late_payment: response.data.late_payment, //是否遲交
              late_payment_amount: response.data.late_payment_amount, //遲交但已供款金額
              late_payment_extra_amount:
                response.data.late_payment_extra_amount, //遲交但先前供多了的款項
              message: "",
            },
          };
        })
        .catch((error) => {
          new_early_repayment.value.message = error.response.data.error;
          show.value = true;
        });
    };

    const changePaymentDate = () => {
      getEarlyRepaymentInfo();
    };

    const resetModal = () => {
      new_early_repayment.value = {
        client_loan_id: router.currentRoute.params.contract_id,
        payment_date: `${store.state.app.maxDate} 00:00`,
        receipt_date: `${store.state.app.maxDate} 00:00`,
        receive_method_id: 1,
      };
      getEarlyRepaymentInfo();
    };

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      resetModal,
      new_early_repayment,
      changePaymentDate,
      show,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
