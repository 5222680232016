<template>
  <component :is="contractData === undefined ? 'div' : 'b-card'">
    <b-alert variant="danger" :show="contractData === undefined">
      <h4 class="alert-heading">Error fetching contract data</h4>
      <div class="alert-body">
        No record found with this id. Check
        <b-link class="alert-link" :to="{ name: 'contracts-list' }">
          contract List
        </b-link>
        for other record.
      </div>
    </b-alert>
    <repayment-edit-tab-information
      v-if="contractData"
      :contract-data="contractData"
      :receive-method-options="receiveMethodOptions"
      :receive-account-options="receiveAccountOptions"
      :interest-type-options="interestTypeOptions"
      :contractStatusesOptions="contractStatusesOptions"
      @refetch-data="refetchData"
      class=""
    />
  </component>
</template>

<script>
import { BCard, BAlert, BLink, BTabs, BTab } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import repaymentStoreModule from "../repaymentStoreModule";
import repaymentEditTabInformation from "./repaymentEditTabInformation.vue";

export default {
  components: {
    repaymentEditTabInformation,
    BCard,
    BAlert,
    BTabs,
    BTab,
    BLink,
  },
  setup() {
    const contractData = ref({ contracts: [{ id: "0" }] });

    const contract = {
      id: 0,
      contract_number: "",
      loan_type_id: null,
      supplier_create_id: null,
      supplier_name_cn: null,
      supplier_name_en: null,
      moneylender_number: null,
      supplier_address: null,
      name_cn: null,
      name_en: null,
      id_number: null,
      br_number: null,
      //get today date
      loan_date: new Date().toISOString().slice(0, 10),
      amount: null,
      interest_type_id: null,
      period: null,
      is_monthly: 1,
      first_pay_date: null,
      annual_interest_rate: null,
      pay_date: null,
      loan_information: {
        application_date: null,
        loan_purpose: null,
        customer_source: null,
        date_of_birth: null,
        gender_id: null,
        mobile: null,
        tel: null,
        address: null,
        rent_or_mortagage: null,
        living_expenses: null,
        company_name: null,
        company_address: null,
        position: null,
        work_age: null,
        payment_method: null,
        pt_company_name: null,
        pt_company_address: null,
        pt_position: null,
        pt_work_age: null,
        pt_payment_method: null,
        monthly_salary: null,
        pt_monthly_salary: null,
        histories: [],
        schedules: [],
        average_amount: null,
        total_amount: null,
      },
    };

    const repayment_APP_STORE_MODULE_NAME = "repayment";

    if (!store.hasModule(repayment_APP_STORE_MODULE_NAME))
      store.registerModule(
        repayment_APP_STORE_MODULE_NAME,
        repaymentStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(repayment_APP_STORE_MODULE_NAME))
        store.unregisterModule(repayment_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      store
        .dispatch("repayment/fetchContract", {
          id: router.currentRoute.params.contract_id,
        })
        .then((response) => {
          contractData.value = { ...response.data.contract };
          contractData.value.is_early_repayment = contractData.value.payment_schedules.some(ele => ele.is_early_repayment == 1)
        })
        .catch((error) => {
          console.log("error when fetching contract", error);
          if (error.response.status === 404) {
            contractData.value = undefined;
          }
        });
    };



    const receiveMethodOptions = ref([]);
    const receiveAccountOptions = ref([]);
    const interestTypeOptions = ref([]);
    const contractStatusesOptions = ref([]);

    const fetchReceiveAccount = () => {
      store
        .dispatch("repayment/fetchReceiveAccounts")
        .then((response) => {
          receiveAccountOptions.value = [...response.data.receive_accounts];
        })
        .catch((error) => {
          if (error.response.status === 404) {
            receiveAccountOptions.value = undefined;
          }
        });
    };

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          receive_method: true,
          interest_type: true,
          contract_status: true,
        })
        .then((response) => {
          receiveMethodOptions.value = response.data.receive_methods;
          interestTypeOptions.value = response.data.interest_types;
          contractStatusesOptions.value = response.data.contract_statuses;
        })
        .catch((error) => {
          console.log(error)
        });
    };

    onMounted(() => {
        refetchData();
        refetchOption();
        fetchReceiveAccount();
    });

    return {
      contractData,
      refetchData,
      receiveMethodOptions,
      receiveAccountOptions,
      interestTypeOptions,
      contractStatusesOptions,
    };
  },
};
</script>
