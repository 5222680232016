
<template>
  <div>
    <modal-image-viewer
      id="5"
      :image-list="previewImage"
      :selected_file="0"
    ></modal-image-viewer>

    <modal-print-receipt :print_list="print_list"></modal-print-receipt>

    <modal-contract-status
      v-if="
        newContractData &&
        contractStatusesOptions &&
        contractStatusesOptions.length > 0
      "
      :contract_id="newContractData.id"
      :contract_status_id="newContractData.contract_status_id"
      :contractStatusesOptions="contractStatusesOptions"
      @refetch-data="$emit('refetch-data')"
    ></modal-contract-status>

    <!-- 
      :trigger-check.sync="triggerCheck" -->
    <modal-early-repayment
      v-if="newContractData && newContractData.remain_balance != 0"
      :receiveMethodOptions="receiveMethodOptions"
      :receiveAccountOptions="receiveAccountOptions"
      @refetch-data="$emit('refetch-data')"
    />

    <modal-pdf id="-export-receipt" :pdfUrl="pdfUrl"></modal-pdf>

    <b-alert variant="danger" :show="newContractData === undefined">
      <h4 class="alert-heading">Error fetching contract data</h4>
      <div class="alert-body">
        No record found with this id. Check
        <b-link class="alert-link" :to="{ name: 'contracts-list' }">
          contract List
        </b-link>
        for other record.
      </div>
    </b-alert>

    <div
      v-if="
        newContractData &&
        newContractData.payment_schedules &&
        Object.keys(newContractData).length > 0
      "
    >
      <b-card no-body class="p-2 bgCommon">
        <!-- contract Info: Top col -->
        <div
          class="d-flex flex-wrap align-items-center justify-content-between pb-2"
        >
          <h5
            class="mb-0 ml-0 mb-1 mb-sm-0 font-weight-bolder d-flex text-nowrap colorBlack"
          >
            <font-awesome-icon
              style="font-size: 18px"
              class="mr-50 ml-sm-1"
              icon="fa-solid fa-puzzle-piece"
            />
            登記還款
          </h5>
          <div class="d-flex flex-wrap justify-content-end">
            <b-button
              class="mobile-w100 mb-1 mb-sm-0 px-3 mx-1"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              返回
            </b-button>
          </div>
        </div>
        <hr />
        <div class="row mb-1">
          <b-col cols="12" md="5" class="info-wrap style-2 p-1">
            <div>
              <div
                class="d-flex aling-items-center text-underline mb-2 colorBlack"
              >
                客戶資料
              </div>
              <div class="pl-1">
                <h5 class="colorBlack">
                  {{ newContractData.name_cn }}
                </h5>
                <h6 style="font-size: 1rem" class="colorBlack">
                  {{ newContractData.name_en }}
                </h6>
              </div>
            </div>
            <hr />
            <div class="row px-2">
              <div class="info-item-wrapper">
                <div class="info-item">
                  <font-awesome-icon
                    class="info-item-icon"
                    icon="fa-solid fa-calendar"
                  />
                  <span
                    >{{
                      Number(new Date().getFullYear()) -
                      Number(
                        newContractData.loan_information.date_of_birth.slice(
                          0,
                          4
                        )
                      )
                    }}歲</span
                  >
                </div>

                <div class="info-item">
                  <font-awesome-icon
                    class="info-item-icon"
                    icon="fa-solid fa-phone"
                  />
                  <span>{{ newContractData.loan_information.mobile }}</span>
                </div>

                <div class="info-item">
                  <font-awesome-icon
                    class="info-item-icon"
                    icon="fa-solid fa-phone"
                  />
                  <span>{{ newContractData.loan_information.tel }}</span>
                </div>

                <div class="info-item">
                  <font-awesome-icon
                    class="info-item-icon"
                    icon="fa-solid fa-id-card"
                  /><b-link
                    class="color1"
                    v-if="ability.can('read', 'contract') || true"
                    :to="`/contracts/list/${newContractData.id_number}`"
                    >{{ newContractData.id_number }}</b-link
                  >
                </div>

                <div class="info-item">
                  <font-awesome-icon
                    class="info-item-icon"
                    icon="fa-solid fa-location-dot"
                  />
                  <span>{{ newContractData.address }}</span>
                </div>
              </div>
              <div class="info-item-wrapper">
                <div class="info-item">
                  <span class="info-item-label">職位：</span>
                  <span>{{ newContractData.loan_information.position }}</span>
                </div>

                <div class="info-item">
                  <span class="info-item-label">薪金：</span>
                  <span>{{
                    newContractData.loan_information.monthly_salary
                  }}</span>
                </div>

                <div class="info-item">
                  <span class="info-item-label">兼職薪金：</span>
                  <span>{{
                    newContractData.loan_information.pt_monthly_salary
                  }}</span>
                </div>

                <div class="info-item">
                  <span class="info-item-label">實際每月薪金：</span>
                  <span>{{
                    Number(newContractData.loan_information.monthly_salary) +
                    Number(newContractData.loan_information.pt_monthly_salary)
                  }}</span>
                </div>

                <div class="info-item">
                  <span class="info-item-label">備註：</span>
                  <span>{{ newContractData.remark }}</span>
                </div>
              </div>
            </div>
          </b-col>
          <!-- 收款 & 合約資料 -->
          <b-col cols="12" md="7" class="p-1 bgGrey">
            <div class="p-2 receive_payment">
              <div
                class="d-flex aling-items-center justify-content-between mb-2"
              >
                <h4 class="text-underline">
                  <span>收款 & 合約資料</span>
                </h4>
                <div>
                  <b class="colorBlack">貸款日期：</b>
                  <span>{{ newContractData.loan_date }}</span>
                </div>
              </div>
              <div class="item-4-group d-flex flex-wrap">
                <div class="item">
                  <b-row>
                    <b-col md="6"><b class="colorBlack">合約編號：</b></b-col>
                    <b-col md="6">
                      <b-link
                        class="color1"
                        v-if="ability.can('update', 'contracts') || true"
                        :to="{
                          name: 'contracts-edit',
                          params: { id: newContractData.id },
                        }"
                      >
                        {{ newContractData.contract_number }}
                      </b-link>
                    </b-col>
                  </b-row>
                </div>
                <div class="item">
                  <b-row>
                    <b-col md="6"><b class="colorBlack">貸款本金：</b></b-col>
                    <b-col md="6">
                      {{ newContractData.amount }}
                    </b-col>
                  </b-row>
                </div>
                <div class="item">
                  <b-row>
                    <b-col md="6"><b class="colorBlack">利息計算：</b></b-col>
                    <b-col md="6">
                      {{
                        interestTypeOptions &&
                        interestTypeOptions.length > 0 &&
                        interestTypeOptions.filter(
                          (ele) => ele.id == newContractData.interest_type_id
                        )[0].name
                      }}
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="item">
                  <b-row>
                    <b-col md="6"><b class="colorBlack">供款週期：</b></b-col>
                    <b-col md="6">
                      {{
                        loan_is_monthly.filter(
                          (ele) => ele.id == newContractData.is_monthly
                        )[0].name
                      }}
                    </b-col>
                  </b-row>
                </div>
                <div class="item">
                  <b-row>
                    <b-col md="6"><b class="colorBlack">期數：</b></b-col>
                    <b-col md="6">
                      {{ newContractData.period }}
                    </b-col>
                  </b-row>
                </div>
                <div class="item">
                  <b-row>
                    <b-col md="6"><b class="colorBlack">年利率：</b></b-col>
                    <b-col md="6">
                      {{ newContractData.annual_interest_rate }}
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="item">
                  <b-row>
                    <b-col md="6"><b class="colorBlack">供款日：</b></b-col>
                    <b-col md="6">
                      {{ newContractData.pay_date }}
                    </b-col>
                  </b-row>
                </div>
                <div class="item">
                  <b-row>
                    <b-col md="6"
                      ><b class="colorBlack"
                        >供款金額：
                        <div>(每期)</div></b
                      ></b-col
                    >
                    <b-col md="6">
                      {{
                        newContractData.average_amount &&
                        formatInput(newContractData.average_amount)
                      }}
                    </b-col>
                  </b-row>
                </div>
                <div class="item">
                  <b-row>
                    <b-col md="6"><b class="colorBlack">總需供款額：</b></b-col>
                    <b-col md="6">
                      {{
                        newContractData.total_amount &&
                        formatInput(newContractData.total_amount)
                      }}
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="item">
                  <b-row>
                    <b-col md="6"><b class="colorBlack">總利息：</b></b-col>
                    <b-col md="6">
                      {{
                        newContractData.total_interest &&
                        formatInput(newContractData.total_interest)
                      }}
                    </b-col>
                  </b-row>
                </div>
                <div v-if="newContractData.supplier.late_time" class="item">
                  <b-row>
                    <b-col md="6"
                      ><b class="colorBlack">逾期還款時間：</b></b-col
                    >
                    <b-col md="6">
                      {{ newContractData.supplier.late_time }}
                    </b-col>
                  </b-row>
                </div>
                <div class="item">
                  <b-row>
                    <b-col md="6"><b class="colorBlack">合約狀態：</b></b-col>
                    <b-col md="6">
                      {{ newContractData.status }}
                    </b-col>
                    <!-- <b-col
                      md="6"
                      class="cursor-pointer"
                      @click="$bvModal.show('modal-contract-status')"
                    >
                      <span v-if="newContractData.contract_status_id">
                        {{
                          contractStatusesOptions &&
                          contractStatusesOptions.length > 0 &&
                          contractStatusesOptions.filter(
                            (ele) =>
                              ele.id == newContractData.contract_status_id
                          )[0].name
                        }}
                      </span>
                      <font-awesome-icon class="ml-1" icon="fa-solid fa-pen" />
                    </b-col> -->
                  </b-row>
                </div>
                <hr />
                <div class="item">
                  <b-row>
                    <b-col md="6"><b class="colorBlack">尚欠總金額：</b></b-col>
                    <b-col md="6">
                      {{ newContractData.remain_balance }}
                    </b-col>
                  </b-row>
                </div>

                <div class="item">
                  <b-row>
                    <b-col md="6"><b class="colorBlack">已還款額：</b></b-col>
                    <b-col md="6">
                      {{ newContractData.total_paid_amount }}
                    </b-col>
                  </b-row>
                </div>

                <hr />
              </div>
            </div>
          </b-col>
        </div>
        <hr />
        <div class="p-1">
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="d-flex aling-items-center text-underline">進度資料</h4>
            <b-row class="aling-items-center">
              <div class="mr-2 d-flex aling-items-center">
                <label style="line-height: 2.2rem; font-size: 14px" for="is_oca"
                  >OCA追數：</label
                >
                <b-form-checkbox
                  v-model="is_oca"
                  id="is_oca"
                  @change="onOCAStatusChange"
                  style="margin-top: 5px"
                >
                </b-form-checkbox>
              </div>
              <div class="mr-2 d-flex aling-items-center">
                <label
                  style="line-height: 2.2rem; font-size: 14px"
                  for="is_bad_debt"
                  >撇帳：</label
                >
                <b-form-checkbox
                  v-model="is_bad_debt"
                  id="is_bad_debt"
                  @change="onBadDebtStatusChange"
                  style="margin-top: 5px"
                >
                </b-form-checkbox>
              </div>
              <div class="mr-2">
                <b-button
                  v-if="newContractData.remain_balance != 0"
                  @click="triggerEarlyRepayment"
                >
                  提早還清
                </b-button>
              </div>
            </b-row>
          </div>
          <div class="">
            <div
              class="time-line-group d-flex flex-wrap align-items-end justify-content-between"
            >
              <div class="group">
                <app-timeline class="horizontal mt-3">
                  <app-timeline-item
                    :icon="
                      item.is_paid
                        ? '@/assets/images/icons/tick.png'
                        : '@/assets/images/icons/question.png'
                    "
                    :class="[
                      item.is_paid ? 'active' : '',
                      selected_item == item.id ? 'selected' : '',
                    ]"
                    v-for="(
                      item, index
                    ) in newContractData.payment_schedules.filter(
                      (ele) => ele.display == 1
                    )"
                    :key="'app-timeline' + item.id + index"
                    @click="triggerSelectedItem(item)"
                  >
                    <div
                      class="timeline-item-content"
                      :class="item.is_paid ? 'active' : null"
                    >
                      <div class="text mb-50">期數{{ item.period }}</div>
                      <div>
                        {{ item.pay_date }}
                      </div>
                      <div
                        class="mt-50 colorGreen1"
                        v-if="item.is_early_repayment"
                      >
                        提早還清款項
                      </div>
                    </div>
                  </app-timeline-item>
                </app-timeline>
              </div>
            </div>
            <hr />
            <div class="d-flex align-items-center">
              <label class="mb-0">顯示</label>
              <v-select
                v-model="perPageSchedule"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptionsSchedule"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label class="mb-0">條目</label>
            </div>
            <b-table
              ref="refPaymentsScheduleTable"
              class="position-relative payment_schecule_table"
              :items="paymentScheduleData"
              responsive
              :fields="tableSchedulesColumns"
              primary-key="id"
              :sort-by.sync="sortBySchedule"
              show-empty
              empty-text="未找到匹配的記錄"
              :sort-desc.sync="isSortDirDescSchedule"
              :tbody-tr-class="getRowClass"
              @row-clicked="(row) => triggerSelectedItem(row)"
            >
              <template #cell(initial_principal)="data">
                {{ Math.round(Number(data.item.initial_principal)) }}
              </template>

              <template #cell(principal)="data">
                {{ Math.round(Number(data.item.principal)) }}
              </template>

              <template #cell(payment_amount)="data">
                {{ Math.round(Number(data.item.payment_amount)) }}
              </template>

              <template #cell(interest)="data">
                {{ Math.round(Number(data.item.interest)) }}
              </template>

              <template #cell(remaining_principal)="data">
                {{ Math.round(Number(data.item.remaining_principal)) }}
              </template>

              <template #cell(actual_amount)="data">
                {{ Math.round(Number(data.item.actual_amount)) }}
              </template>

              <template #cell(actual_paid_amount)="data">
                {{ Math.round(Number(data.item.actual_paid_amount)) }}
              </template>

              <template #cell(remaining_amount)="data">
                <div>
                  {{
                    Math.round(
                      Number(data.item.actual_amount) -
                        Number(data.item.actual_paid_amount)
                    )
                  }}
                </div>
              </template>

              <template #cell(postpone_date)="data">
                {{ data.item.postpone_date }}
                <div
                  v-if="data.item.postpone_date"
                  class="d-flex align-items-center"
                >
                  延期{{ data.item.postpone_day }}日 X
                  {{ data.item.postpone_fee }}元 =
                  {{ data.item.postpone_day * data.item.postpone_fee }}
                </div>
                <div v-else>- -</div>
              </template>
              <template #cell(defer_date)="data">
                {{ data.item.defer_date }}
                <div
                  v-if="data.item.defer_date"
                  class="d-flex align-items-center"
                >
                  遲交{{ data.item.defer_day }}日 X {{ data.item.defer_fee }}元
                  = {{ data.item.defer_day * data.item.defer_fee }}
                </div>
                <div v-else>- -</div>
              </template>

              <template #cell(additional_fee)="data">
                {{ Math.round(Number(data.item.additional_fee)) }}
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted"
                    >在{{ dataMetaSchedule.of }}個記錄中，正在顯示
                    {{ dataMetaSchedule.from }} 至
                    {{ dataMetaSchedule.to }}個記錄</span
                  >
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPageSchedule"
                    :total-rows="totalRowSchedule"
                    :per-page="perPageSchedule"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <hr />
        <div v-if="target_schedule">
          <span v-if="payOffDisableCreate()" class="redColor"
            >(已還清款項不可更改)</span
          >
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-overlay
              :show="loading"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
            >
              <b-form
                class="pt-1"
                @submit.prevent="handleSubmit(onSubmitSchedule)"
              >
                <div class="item-6-group d-flex flex-wrap">
                  <div class="item">
                    <b-row class="mb-1 cal-input-group">
                      <!-- postpone_date -->
                      <b-col md="3" class="label">
                        <!-- 延期:{{ target_schedule.postpone_day }}日 -->
                        延期:
                      </b-col>
                      <b-col md="9">
                        <div class="content-wrapper d-flex align-items-center">
                          <b-input-group class="input2" size="md" append="日">
                            <b-form-input
                              id="postpone_day"
                              class="input1"
                              type="number"
                              v-model="target_schedule.postpone_day"
                              :disabled="payOffDisableCreate()"
                              placeholder="輸入延期日數"
                            />
                            <!-- @input="(val) => calculatePostponeDate(val)" -->
                          </b-input-group>
                          <div class="operator">/</div>
                          <flat-pickr
                            class="form-control input1"
                            :disabled="payOffDisableCreate()"
                            v-model="target_schedule.postpone_date"
                            :config="{
                              altInput: true,
                              altFormat: 'Y-m-d',
                              allowInput: true,
                              dateFormat: 'Y-m-d',
                              minDate: `${target_schedule.pay_date}`,
                            }"
                            placeholder="輸入延期日期"
                          />
                          <!-- postpone_fee -->
                          <div class="operator">X</div>
                          <b-input-group
                            class="input2"
                            size="md"
                            prepend="$"
                            append="每天"
                          >
                            <b-form-input
                              id="postpone_fee"
                              class="input2"
                              type="number"
                              :disabled="payOffDisableCreate()"
                              v-model="target_schedule.postpone_fee"
                              placeholder="輸入延期金額"
                            />
                          </b-input-group>
                        </div>
                      </b-col>
                    </b-row>
                  </div>

                  <div class="item">
                    <b-form-group
                      label="延期備註："
                      label-for="postpone_remark"
                      label-cols-md="3"
                    >
                      <b-form-input
                        id="postpone_remark"
                        :disabled="payOffDisableCreate()"
                        v-model="target_schedule.postpone_remark"
                        placeholder="輸入延期備註"
                      />
                    </b-form-group>
                  </div>

                  <div class="item">
                    <b-row class="mb-1 cal-input-group">
                      <!-- defer_day -->
                      <b-col md="3" class="label">遲交:</b-col>

                      <b-col md="9">
                        <div class="content-wrapper d-flex align-items-center">
                          <b-input-group class="input2" size="md" append="日">
                            <b-form-input
                              id="defer_day"
                              class="input1"
                              :disabled="payOffDisableCreate()"
                              type="number"
                              v-model="target_schedule.defer_day"
                              placeholder="輸入遲交日數"
                            />
                            <!-- @input="(val) => calculateDeferDate(val)" -->
                          </b-input-group>
                          <div class="operator">/</div>
                          <flat-pickr
                            v-model="target_schedule.defer_date"
                            :disabled="payOffDisableCreate()"
                            class="form-control input1"
                            :config="{
                              minDate: defer_minDate,
                              altInput: true,
                              altFormat: 'Y-m-d',
                              allowInput: true,
                              dateFormat: 'Y-m-d',
                            }"
                            placeholder="輸入遲交日期"
                          />
                          <!-- defer_fee -->
                          <div class="operator">X</div>
                          <b-input-group
                            class="input2"
                            size="md"
                            prepend="$"
                            append="每天"
                          >
                            <b-form-input
                              id="defer_fee"
                              type="number"
                              :disabled="payOffDisableCreate()"
                              v-model="target_schedule.defer_fee"
                              placeholder="輸入遲交金額"
                            />
                          </b-input-group>
                        </div>
                      </b-col>
                    </b-row>
                  </div>

                  <div class="item">
                    <b-form-group
                      label="遲交備註："
                      label-for="defer_remark"
                      label-cols-md="3"
                    >
                      <b-form-input
                        id="defer_remark"
                        :disabled="payOffDisableCreate()"
                        v-model="target_schedule.defer_remark"
                        placeholder="輸入延期備註"
                      />
                    </b-form-group>
                  </div>

                  <div class="item">
                    <b-form-group
                      label="附加費："
                      label-for="additional_fee"
                      label-cols-md="3"
                    >
                      <b-input-group size="md" prepend="$">
                        <b-form-input
                          id="additional_fee"
                          type="number"
                          :disabled="payOffDisableCreate()"
                          v-model="target_schedule.additional_fee"
                          placeholder="輸入附加費"
                        />
                      </b-input-group>
                    </b-form-group>
                  </div>

                  <div class="item">
                    <b-form-group
                      label="附加備註："
                      label-for="additional_remark"
                      label-cols-md="3"
                    >
                      <b-form-input
                        id="additional_remark"
                        :disabled="payOffDisableCreate()"
                        v-model="target_schedule.additional_remark"
                        placeholder="輸入附加備註"
                      />
                    </b-form-group>
                  </div>

                  <div class="item" v-if="payOffDisableCreate() == false">
                    <div class="mx-xl-1 my-1 d-flex flex-wrap amount-info">
                      <div class="d-flex align-items-center col-sm-6">
                        <span>當期供款：</span
                        >{{
                          Math.round(Number(target_schedule.payment_amount))
                        }}
                      </div>

                      <div class="d-flex align-items-center col-sm-6">
                        <span>供款總計：</span>{{ cal_this_period_amount }}
                      </div>

                      <div class="d-flex align-items-center col-sm-6">
                        <span>延期費用：</span>{{ cal_postpone_amount }}
                      </div>

                      <div class="d-flex align-items-center col-sm-6">
                        <span>已付金額：</span
                        >{{ target_schedule.actual_paid_amount }}
                      </div>

                      <div class="d-flex align-items-center col-sm-6">
                        <span>遲交費用：</span>{{ cal_defer_amount }}
                      </div>

                      <div class="d-flex align-items-center col-sm-6">
                        <span>尚欠金額：</span>
                        {{ calOwed }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="newContractData.remain_balance != 0"
                  class="d-flex flex-wrap justify-content-end align-items-center mb-2"
                >
                  <span style="color: rgb(210 151 151)" class="mr-2"
                    >請記得提交資料！</span
                  >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mobile-w100"
                    type="submit"
                  >
                    <feather-icon
                      size="16"
                      class="mr-1"
                      icon="CheckCircleIcon"
                    />
                    提交
                  </b-button>
                </div>
              </b-form>
            </b-overlay>
          </validation-observer>
          <hr />
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserverB"
          >
            <b-overlay
              :show="loadingB"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
            >
              <b-form
                class="mt-3"
                @submit.prevent="
                  handleSubmit(() => onSubmitPayment(newPaymentData))
                "
              >
                <div :key="triggerKey" class="d-flex align-items-center pb-2">
                  <h5 class="colorBlack mb-0">新增記錄</h5>
                  <span v-if="payOffDisableCreate()" class="redColor ml-2"
                    >(已還清款項不可新增記錄)</span
                  >
                </div>
                <b-row>
                  <!-- receive_method -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="收款方式"
                      rules="required"
                    >
                      <b-form-group
                        label="收款方式："
                        label-for="receive_method"
                        label-cols-md="3"
                        class="required"
                      >
                        <v-select
                          :disabled="payOffDisableCreate()"
                          v-model="newPaymentData.receive_method_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="receiveMethodOptions"
                          label="name"
                          :clearable="false"
                          :reduce="(option) => option.id"
                          @input="
                            receiveAccountDisabled(
                              newPaymentData,
                              newPaymentData.receive_method_id
                            )
                          "
                          placeholder="選擇收款方式"
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- receive_account_id -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="收款帳號"
                      :rules="
                        newPaymentData.receive_method_id > 1 ? 'required' : ''
                      "
                    >
                      <b-form-group
                        label="收款帳號："
                        label-for="receive_account_id"
                        label-cols-md="3"
                        :class="
                          newPaymentData.receive_method_id > 1 ? 'required' : ''
                        "
                      >
                        <v-select
                          v-model="newPaymentData.receive_account_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="receiveAccountOptions"
                          :disabled="
                            payOffDisableCreate() ||
                            newPaymentData.receive_method_id == 1
                          "
                          label="name"
                          :clearable="false"
                          :reduce="(option) => option.id"
                          placeholder="選擇收款帳號"
                        >
                          <template #option="info">
                            {{ info.bank }} - {{ info.account_number }}
                          </template>
                          <template #selected-option="info">
                            {{ info.bank }} - {{ info.account_number }}
                          </template>
                        </v-select>

                        <b-form-invalid-feedback
                          :state="getValidationStateB(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- receipt_number -->
                  <b-col cols="12" md="6">
                    <!-- <validation-provider
                      #default="validationContext"
                      name="收據編號"
                      rules="required"
                    > -->
                    <b-form-group
                      label="收據編號："
                      label-for="receipt_number"
                      label-cols-md="3"
                    >
                      <!-- class="required" -->
                      <b-form-input
                        disabled
                        id="receipt_number"
                        v-model="newPaymentData.receipt_number"
                        placeholder="系統自動生成收據編號"
                      />
                      <!-- :state="getValidationState(validationContext)" -->
                      <!-- <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback> -->
                    </b-form-group>
                    <!-- </validation-provider> -->
                  </b-col>

                  <!-- receipt_date -->
                  <b-col cols="12" md="6">
                    <!-- <validation-provider
                      #default="validationContext"
                      name="收據日期 & 時間"
                      class="required"
                    > -->
                    <b-form-group
                      label="收據日期 & 時間："
                      label-for="receipt_date"
                      label-cols-md="3"
                    >
                      <!-- class="required" -->
                      <flat-pickr
                        :disabled="payOffDisableCreate()"
                        v-model="newPaymentData.receipt_date"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          altInput: true,
                          altFormat: 'Y-m-d H:i',
                          allowInput: true,
                          dateFormat: 'Y-m-d H:i',
                        }"
                        placeholder="輸入收據日期 & 時間"
                      />
                      <!-- <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback> -->
                    </b-form-group>
                    <!-- </validation-provider> -->
                  </b-col>

                  <!-- repayment_date -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="還款日期 & 時間"
                      rules="required"
                    >
                      <b-form-group
                        label="還款日期 & 時間："
                        label-for="payment_date"
                        label-cols-md="3"
                        class="required"
                      >
                        <flat-pickr
                          :disabled="payOffDisableCreate()"
                          v-model="newPaymentData.payment_date"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            altInput: true,
                            altFormat: 'Y-m-d H:i',
                            allowInput: true,
                            dateFormat: 'Y-m-d H:i',
                          }"
                          placeholder="輸入還款日期 & 時間"
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="還款金額"
                      rules="required"
                    >
                      <b-form-group
                        label="還款金額："
                        label-for="amount"
                        label-cols-md="3"
                        class="required"
                      >
                        <b-input-group
                          size="md"
                          prepend="$"
                          :append="`尚欠: ${calPaymentOwed(
                            newPaymentData.amount
                          )}`"
                        >
                          <b-form-input
                            id="amount"
                            :disabled="payOffDisableCreate()"
                            v-model="newPaymentData.amount"
                            :state="getValidationStateB(validationContext)"
                            placeholder="輸入還款金額"
                          />
                        </b-input-group>
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group
                      label="備註："
                      label-for="remark"
                      label-cols-md="3"
                    >
                      <b-form-input
                        :disabled="payOffDisableCreate()"
                        id="remark"
                        v-model="newPaymentData.remark"
                        placeholder="輸入備註"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12"> </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    v-if="newContractData.remain_balance != 0"
                  >
                    <upload-file
                      key="fileupload1"
                      ref="childRef1"
                      title="付費"
                      custome_id="1"
                      v-on:file-uploaded="onPayFileUploaded"
                    ></upload-file>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    v-if="newContractData.remain_balance != 0"
                  >
                    <upload-file
                      key="fileupload2"
                      ref="childRef2"
                      title="其他"
                      custome_id="2"
                      v-on:file-uploaded="onOtherFileUploaded"
                    ></upload-file>
                  </b-col>
                </b-row>
                <div
                  class="d-flex justify-content-end"
                  v-if="newContractData.remain_balance != 0"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1 mobile-w100"
                    type="submit"
                  >
                    <feather-icon
                      size="16"
                      class="mr-1"
                      icon="CheckCircleIcon"
                    />
                    提交
                  </b-button>
                </div>
              </b-form>
            </b-overlay>
          </validation-observer>
          <hr class="my-1" />
          <b-overlay
            :show="loadingRecord"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <h5 class="colorBlack ml-1 py-2">還款詳情</h5>
                <div class="d-flex ml-3 align-items-center">
                  <label>顯示</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                  />
                  <label>條目</label>
                </div>
              </div>
              可按文件以放大觀看
            </div>
            <b-table
              ref="refRepaymentsListTable"
              class="position-relative detail_table"
              :items="paymentListData"
              responsive
              :fields="tablePaymentsColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="未找到匹配的記錄"
              :sort-desc.sync="isSortDirDesc"
            >
              <template #cell(receive_method_id)="data">
                {{
                  receiveMethodOptions &&
                  receiveMethodOptions.length > 0 &&
                  receiveMethodOptions.filter(
                    (ele) => ele.id == data.item.receive_method_id
                  )[0].name
                }}
              </template>
              <template #cell(receive_account_id)="data">
                <div v-if="data.item.receive_account_id">
                  {{
                    receiveAccountOptions &&
                    receiveAccountOptions.length > 0 &&
                    receiveAccountOptions.filter(
                      (ele) => ele.id == data.item.receive_account_id
                    )[0].account_number
                  }}
                </div>
              </template>

              <template #cell(payment_receipts)="data">
                <ul class="table-image-group">
                  <li class="">
                    <!-- :key="doc.id"
                    v-for="doc of data.item.payment_documents" -->

                    <a
                      href="javascript:void(0)"
                      @click="tirggerViewer(data.item.payment_receipts)"
                      v-if="
                        data.item.payment_receipts &&
                        data.item.payment_receipts.length > 0
                      "
                    >
                      <embed
                        v-if="
                          data.item.payment_receipts[0].original_image_path.indexOf(
                            'pdf'
                          ) > -1
                        "
                        class="item"
                        :src="data.item.payment_receipts[0].image_path"
                        type="application/pdf"
                      />
                      <img
                        v-else
                        class="item"
                        :src="data.item.payment_receipts[0].image_path"
                        alt="image slot"
                      />
                    </a>
                    <template v-else> - - </template>
                  </li>
                </ul>
              </template>

              <template #cell(payment_documents)="data">
                <ul class="table-image-group">
                  <li class="">
                    <!-- :key="doc.id"
                    v-for="doc of data.item.payment_documents" -->

                    <a
                      href="javascript:void(0)"
                      @click="tirggerViewer(data.item.payment_documents)"
                      v-if="
                        data.item.payment_documents &&
                        data.item.payment_documents.length > 0
                      "
                    >
                      <embed
                        v-if="
                          data.item.payment_documents[0].original_image_path.indexOf(
                            'pdf'
                          ) > -1
                        "
                        class="item"
                        :src="data.item.payment_documents[0].image_path"
                        type="application/pdf"
                      />
                      <img
                        v-else
                        class="item"
                        :src="data.item.payment_documents[0].image_path"
                        alt="image slot"
                      />
                    </a>
                    <template v-else> - - </template>
                  </li>
                </ul>
              </template>

              <template #cell(amount)="data">
                {{ Math.round(Number(data.item.amount)) }}
              </template>

              <template #cell(remark)="data">
                <span>{{
                  data.item.remark && data.item.remark !== "undefined"
                    ? data.item.remark
                    : "- -"
                }}</span>
              </template>

              <template #row-details="rowData">
                <validation-observer
                  #default="{ handleSubmit }"
                  ref="refFormObserverC"
                  :key="rowData.item.id"
                >
                  <b-overlay
                    :show="loadingB"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                    rounded="sm"
                  >
                    <b-form
                      class="mt-3 table-form"
                      @submit.prevent="
                        handleSubmit(() => onSubmitPayment(oldPaymentDataEdit))
                      "
                    >
                      <b-row>
                        <!-- receive_method -->
                        <b-col cols="12" md="6">
                          <validation-provider
                            #default="validationContext"
                            name="收款方式"
                            rules="required"
                          >
                            <b-form-group
                              label="收款方式："
                              label-for="receive_method"
                              label-cols-md="3"
                              class="required"
                            >
                              <v-select
                                v-if="
                                  receiveMethodOptions &&
                                  receiveMethodOptions.length
                                "
                                v-model="oldPaymentDataEdit.receive_method_id"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :disabled="payOffDisableEdit()"
                                :options="receiveMethodOptions"
                                label="name"
                                :clearable="false"
                                :reduce="(option) => option.id"
                                @input="
                                  receiveAccountDisabled(
                                    oldPaymentDataEdit,
                                    oldPaymentDataEdit.receive_method_id
                                  )
                                "
                                placeholder="選擇收款方式"
                              />
                              <b-form-invalid-feedback
                                :state="getValidationState(validationContext)"
                              >
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>

                        <!-- receive_account -->
                        <b-col cols="12" md="6">
                          <validation-provider
                            #default="validationContext"
                            name="收款帳號"
                            :rules="
                              oldPaymentDataEdit.receive_method_id > 1
                                ? 'required'
                                : ''
                            "
                          >
                            <b-form-group
                              label="收款帳號："
                              label-for="receive_account_id"
                              label-cols-md="3"
                              :class="
                                oldPaymentDataEdit.receive_method_id > 1
                                  ? 'required'
                                  : ''
                              "
                            >
                              <v-select
                                v-if="
                                  receiveAccountOptions &&
                                  receiveAccountOptions.length
                                "
                                v-model="oldPaymentDataEdit.receive_account_id"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :disabled="
                                  payOffDisableEdit() ||
                                  oldPaymentDataEdit.receive_method_id == 1
                                "
                                :options="receiveAccountOptions"
                                label="name"
                                :clearable="false"
                                :reduce="(option) => option.id"
                                placeholder="選擇收款帳號"
                              >
                                <template #option="info">
                                  {{ info.bank }} - {{ info.account_number }}
                                </template>
                                <template #selected-option="info">
                                  {{ info.bank }} - {{ info.account_number }}
                                </template>
                              </v-select>

                              <b-form-invalid-feedback
                                :state="getValidationStateB(validationContext)"
                              >
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>

                        <!-- receipt_number -->
                        <b-col cols="12" md="6">
                          <!-- <validation-provider
                            #default="validationContext"
                            name="收據編號"
                            rules="required"
                          > -->
                          <b-form-group
                            label="收據編號："
                            label-for="receipt_number"
                            label-cols-md="3"
                          >
                            <!-- class="required" -->
                            <b-form-input
                              id="receipt_number"
                              disabled
                              v-model="oldPaymentDataEdit.receipt_number"
                              placeholder="系統自動生成收據編號"
                            />
                            <!-- :state="getValidationState(validationContext)" -->
                            <!-- <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback> -->
                          </b-form-group>
                          <!-- </validation-provider> -->
                        </b-col>

                        <!-- receipt_date -->
                        <b-col cols="12" md="6">
                          <!-- <validation-provider
                            #default="validationContext"
                            name="收據日期 & 時間"
                            rules="required"
                          > -->
                          <b-form-group
                            label="收據日期 & 時間："
                            label-for="receipt_date"
                            label-cols-md="3"
                          >
                            <!-- class="required" -->
                            <flat-pickr
                              v-model="oldPaymentDataEdit.receipt_date"
                              class="form-control"
                              :disabled="payOffDisableEdit()"
                              :config="{
                                enableTime: true,
                                altInput: true,
                                altFormat: 'Y-m-d H:i',
                                allowInput: true,
                                dateFormat: 'Y-m-d H:i',
                              }"
                              placeholder="輸入收據日期 & 時間"
                            />
                            <!-- <b-form-invalid-feedback
                                :state="getValidationState(validationContext)"
                              >
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback> -->
                          </b-form-group>
                          <!-- </validation-provider> -->
                        </b-col>

                        <!-- repayment_date -->
                        <b-col cols="12" md="6">
                          <validation-provider
                            #default="validationContext"
                            name="還款日期 & 時間"
                            rules="required"
                          >
                            <b-form-group
                              label="還款日期 & 時間："
                              label-for="payment_date"
                              label-cols-md="3"
                              class="required"
                            >
                              <flat-pickr
                                v-model="oldPaymentDataEdit.payment_date"
                                class="form-control"
                                :disabled="payOffDisableEdit()"
                                :config="{
                                  enableTime: true,
                                  altInput: true,
                                  altFormat: 'Y-m-d H:i',
                                  allowInput: true,
                                  dateFormat: 'Y-m-d H:i',
                                }"
                                placeholder="輸入還款日期 & 時間"
                              />
                              <b-form-invalid-feedback
                                :state="getValidationState(validationContext)"
                              >
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>

                        <b-col cols="12" md="6">
                          <validation-provider
                            #default="validationContext"
                            name="還款金額"
                            rules="required"
                          >
                            <b-form-group
                              label="還款金額："
                              label-for="amount"
                              label-cols-md="3"
                              class="required"
                            >
                              <b-form-input
                                id="amount"
                                v-model="oldPaymentDataEdit.amount"
                                :disabled="payOffDisableEdit()"
                                :state="getValidationStateB(validationContext)"
                                placeholder="輸入還款金額"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col cols="12" md="6">
                          <b-form-group
                            label="備註："
                            label-for="remark"
                            label-cols-md="3"
                          >
                            <b-form-input
                              id="remark"
                              :disabled="payOffDisableEdit()"
                              v-model="oldPaymentDataEdit.remark"
                              placeholder="輸入備註"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12"> </b-col>

                        <b-col
                          cols="12"
                          md="6"
                          v-if="payOffDisableEdit() == false"
                        >
                          <!-- :required="true" -->
                          <upload-file
                            key="editFileupload1"
                            title="付費"
                            custome_id="3"
                            :props_previewImage="rowData.item.payment_receipts"
                            v-on:file-uploaded="onEditPayFileUploaded"
                            v-on:file-delete="deletePayDocument"
                          ></upload-file>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          v-if="payOffDisableEdit() == false"
                        >
                          <upload-file
                            key="editFileupload2"
                            title="其他"
                            custome_id="4"
                            :props_previewImage="rowData.item.payment_documents"
                            v-on:file-uploaded="onEditOtherFileUploaded"
                            v-on:file-delete="deleteOtherDocument"
                          ></upload-file>
                        </b-col>
                      </b-row>
                      <div
                        v-if="payOffDisableEdit() == false"
                        class="d-flex justify-content-end align-items-center mb-2"
                      >
                        <span style="color: rgb(210 151 151)" class="mr-2"
                          >請記得提交資料！</span
                        >
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="mobile-w100"
                          type="submit"
                        >
                          <feather-icon
                            size="16"
                            class="mr-1"
                            icon="CheckCircleIcon"
                          />
                          提交
                        </b-button>
                      </div>
                    </b-form>
                  </b-overlay>
                </validation-observer>
              </template>

              <template #cell(action)="data">
                <div class="d-flex align-items-center justify-content-center">
                  <!-- <a href="javascript:void(0)" @click="printReceipt(data.item.payment_receipts)">
                  <font-awesome-icon class="" icon="fa-solid fa-print" />
                </a>
                <span class="mx-50  color1 mt-25">/</span> -->
                  <a
                    href="javascript:void(0)"
                    @click="exportReceipt(data.item.id)"
                  >
                    <feather-icon icon="DownloadIcon" size="18" />
                  </a>
                  <span class="mx-50 color1 mt-25">/</span>
                  <a
                    v-if="ability.can('update', 'repayment') || true"
                    href="javascript:void(0)"
                    @click="targetPaymentEdit(data)"
                  >
                    <font-awesome-icon icon="fa-solid fa-pen" />
                    <!-- <span class="align-middle ml-50">修改</span> -->
                  </a>
                  <span class="mx-50 color1 mt-25" style="font-size: 20px"
                    >/</span
                  >
                  <a
                    v-if="ability.can('delete', 'repayment') || true"
                    href="javascript:void(0)"
                    @click="deletePayment(data.item.id)"
                  >
                    <feather-icon icon="Trash2Icon" />
                    <!-- <span class="align-middle ml-50">刪除記錄</span> -->
                  </a>
                </div>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted"
                    >在{{ dataMeta.of }}個記錄中，正在顯示
                    {{ dataMeta.from }} 至 {{ dataMeta.to }}個記錄</span
                  >
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRow"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BTable,
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BCollapse,
  BBadge,
  BForm,
  BOverlay,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BFormInvalidFeedback,
  BPagination,
  VBToggle,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { onMounted, ref, watch, computed } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import ability from "@/libs/acl/ability";
import router from "@/router";
import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend,
} from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, email } from "@validations";

import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

// import ModalDocument from "@/layouts/components/file/ModalDocument";
// import ModalPdf from "@/layouts/components/file/ModalPdf.vue";
import ModalImageViewer from "@/layouts/components/file/ModalImageViewer.vue";
import uploadFile from "@/layouts/components/file/uploadFile.vue";
import ModalContractStatus from "../modal/ModalContractStatus.vue";
import ModalEarlyRepayment from "../modal/ModalEarlyRepayment.vue";
import ModalPrintReceipt from "../modal/ModalPrintReceipt.vue";
import ModalPdf from "@/layouts/components/file/ModalPdf.vue";
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BCardBody,
    BTable,
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BCollapse,
    BBadge,
    BOverlay,
    BForm,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BFormInvalidFeedback,
    BPagination,
    flatPickr,
    vSelect,
    AppTimeline,
    AppTimelineItem,
    ModalContractStatus,
    ModalEarlyRepayment,
    // ModalDocument,
    // ModalPdf,
    ModalImageViewer,
    ModalPrintReceipt,
    uploadFile,
    ModalPdf,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      ability,
      pdfUrl: null,
      // loadingPDF: false,
      previewImage: null,
      loan_is_monthly: [
        { id: 1, name: "月供" },
        { id: 0, name: "半月供" },
      ],
      // triggerCheck: false,
      print_list: null,
    };
  },
  props: {
    contractData: {
      type: Object,
      required: true,
    },
    receiveMethodOptions: {
      type: Array,
      required: true,
    },
    receiveAccountOptions: {
      type: Array,
      required: true,
    },
    interestTypeOptions: {
      type: Array,
      required: true,
    },
    contractStatusesOptions: {
      type: Array,
      required: true,
    },
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  methods: {
    exportReceipt(id) {
      this.loadingRecord = true;
      this.$store
        .dispatch("repayment/exportPaymentReceipt", { id })
        .then((response) => {
          this.loadingRecord = false;
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf-export-receipt");
        })
        .catch((error) => {
          this.loadingRecord = false;
          console.log({ error });
        });
    },
    triggerEarlyRepayment() {
      this.triggerCheck = true;
      this.$bvModal.show("modal-early-repayment");
    },
    triggerSelectedItem(item) {
      // if(item.is_enable_create_record){
      this.selected_item = item.id;
      // }else{

      // }
    },
    calculatePostponeDate(val) {
      let postpone_day = Number(val);
      if (postpone_day < 0) {
        this.tem_postpone_day = 0;
      }
      const payDate = new Date(this.target_schedule.pay_date);
      payDate.setDate(payDate.getDate() + postpone_day);
      this.target_schedule.postpone_date = payDate.toISOString().split("T")[0];
    },
    calculateDeferDate(val) {
      let defer_day = Number(val);
      if (defer_day < 0) {
        this.tem_defer_day = 0;
      }
      const deferDate = new Date(this.target_schedule.pay_date);
      deferDate.setDate(
        deferDate.getDate() + (this.target_schedule.postpone_day + defer_day)
      );
      this.target_schedule.defer_date = deferDate.toISOString().split("T")[0];
    },
    hide() {
      this.$router.replace("/payment/repayment/list");
    },
    tirggerViewer(files) {
      this.previewImage = files.map((ele) => {
        return {
          id: ele.id,
          src: ele.image_path,
          type:
            ele.original_image_path.indexOf("pdf") > -1
              ? "application/pdf"
              : "image/jpeg",
          is_exist: 1,
        };
      });
      this.$bvModal.show(`modal-image-viewer5`);
    },
    onSubmitSchedule() {
      this.newPaymentScheduleData = {
        id: this.target_schedule.id,
        postpone_date: this.target_schedule.postpone_date,
        postpone_day: this.target_schedule.postpone_day,
        postpone_fee: this.target_schedule.postpone_fee,
        postpone_remark: this.target_schedule.postpone_remark,

        defer_date: this.target_schedule.defer_date,
        defer_day: this.target_schedule.defer_day,
        defer_fee: this.target_schedule.defer_fee,
        defer_remark: this.target_schedule.defer_remark,

        additional_fee: this.target_schedule.additional_fee,
        additional_remark: this.target_schedule.additional_remark,
      };
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("repayment/editRepayment", this.newPaymentScheduleData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              // if (this.loanData.id == 0) {
              //   this.loanData.id = response.data.id;
              //   this.redirect(response.data.id);
              // }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    targetPaymentEdit(row) {
      if (row.detailsShowing) {
        row.toggleDetails();
      } else {
        this.$refs.refRepaymentsListTable.localItems.forEach((item) => {
          this.$set(item, "_showDetails", false);
        });
        this.$nextTick(() => {
          row.toggleDetails();
        });
      }

      this.oldPaymentDataEdit = {
        id: row.item.id,
        is_early_repayment: row.item.is_early_repayment,
        receipt_number: row.item.receipt_number,
        receipt_date: row.item.receipt_date,
        receive_method_id: row.item.receive_method_id,
        payment_date: row.item.payment_date,
        receive_account_id: row.item.receive_account_id
          ? row.item.receive_account_id
          : null,
        amount: Math.round(row.item.amount),
        remark: row.item.remark,
        payment_receipts: [],
        payment_documents: [],
      };
    },
    onSubmitPayment(pData) {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let formData = new FormData();

          formData.append("id", pData.id);
          formData.append("payment_schedule_id", this.target_schedule.id);
          formData.append("receive_method_id", pData.receive_method_id);
          if (pData.receipt_number)
            formData.append("receipt_number", pData.receipt_number);
          if (pData.receipt_date)
            formData.append("receipt_date", pData.receipt_date);

          if (pData.receive_account_id)
            formData.append("receive_account_id", pData.receive_account_id);

          formData.append("amount", pData.amount);
          formData.append("remark", pData.remark ? pData.remark : "");
          formData.append("payment_date", pData.payment_date);

          if (pData.payment_receipts && pData.payment_receipts.length > 0) {
            pData.payment_receipts.forEach((item) => {
              formData.append("payment_receipts[]", item);
            });
          }

          if (pData.payment_documents && pData.payment_documents.length > 0) {
            pData.payment_documents.forEach((item) => {
              formData.append("payment_documents[]", item);
            });
          }

          this.loading = true;
          store
            .dispatch("repayment/editPayment", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              this.$refs.childRef1.removeAll();
              this.$refs.childRef2.removeAll();
              console.log(1);
              this.$emit("refetch-data");

              if (this.loanData.id == 0) {
                this.loanData.id = response.data.id;
                this.redirect(response.data.id);
              }
            })
            .catch((error) => {
              this.loading = false;
              console.log(2);
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    onPayFileUploaded(file) {
      this.newPaymentData.payment_receipts = file;
    },
    onOtherFileUploaded(file) {
      this.newPaymentData.payment_documents = file;
    },
    onEditPayFileUploaded(file) {
      this.oldPaymentDataEdit.payment_receipts = file;
    },
    onEditOtherFileUploaded(file) {
      this.oldPaymentDataEdit.payment_documents = file;
    },
    formatInput(myNumber) {
      try {
        if (myNumber != null) {
          myNumber = Number(Math.round(myNumber));
        }
        return myNumber;
      } catch (error) {
        return null;
      }
    },
    // previewPDF(item, key) {
    //   if (item.type === "application/pdf") {
    //     this.loadingPDF = true;
    //     axiosIns
    //       .get(`contract/${key}?id=${id}`)
    //       .then((response) => {
    //         const filepdf = new Blob([response.data], {
    //           type: "application/pdf",
    //         });
    //         const fileURL = URL.createObjectURL(filepdf);
    //         this.pdfUrl = fileURL;
    //         this.loadingPDF = false;
    //         this.$bvModal.show("modal-pdf");
    //       })
    //       .catch((error) => {
    //         console.log({ error });
    //         this.loadingPDF = false;
    //       });
    //   } else if (
    //     item.type == "image/jpeg" ||
    //     item.type == "image/jpg" ||
    //     item.type == "image/png" ||
    //     item.type == "image/gif" ||
    //     item.type == "image/webp" ||
    //     item.type == "image/svg" ||
    //     item.image_path
    //   ) {
    //     this.previewImage = item.image_path;
    //     this.$bvModal.show("modal-image-viewer");
    //   }
    // },
    deletePayDocument(id) {
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store
            .dispatch("repayment/deletePaymentDocument", { id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    deleteOtherDocument(id) {
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store
            .dispatch("repayment/deletePaymentOtherDocument", { id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    deletePayment(id) {
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store
            .dispatch("repayment/deletePayment", { id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    printReceipt(print_list) {
      this.print_list = null;
      this.print_list = print_list;
      this.$bvModal.show("modal-print-receipt");
    },
    receiveAccountDisabled(item, id) {
      if (id == 1) item.receive_account_id = null;
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
  },
  setup(props,{emit}) {
    const newContractData = ref(null);
    const newPaymentScheduleData = ref({
      id: 0,
    });
    const newPaymentData = ref({
      id: 0,
    });
    const oldPaymentDataEdit = ref({
      id: 0,
    });

    const selected_item = ref(null);
    const target_schedule = ref(null);
    const cal_this_period_amount = ref(null);
    const calOwed = ref(null);
    const defer_minDate = ref(null);
    const cal_defer_amount = ref(0);
    const cal_postpone_amount = ref(0);
    const tem_postpone_day = ref(0);
    const tem_defer_day = ref(0);

    const resetPaymentScheduleData = () => {
      return (newPaymentScheduleData = {
        id: 0,
      });
    };

    const resetPaymentData = () => {
      return (newPaymentData = { id: 0 });
    };

    const resetPaymentDataEdit = () => {
      return (oldPaymentDataEdit = { id: 0 });
    };

    const getRowClass = (row) => {
      if (row.id == selected_item.value) {
        return "high-light-row";
      }
      return "";
    };

    const triggerKey = ref(false);

    // const last_enable_create_record = ref(null);
    watch(
      () => props.contractData,
      (newVal) => {
        newContractData.value = { ...newVal };

        paymentScheduleData.value = [
          ...newContractData.value.payment_schedules,
        ];

        // whole schedule
        refetchTbData(
          "payment_schedules",
          newContractData.value.payment_schedules,
          paymentScheduleData,
          totalRowSchedule,
          dataMetaSchedule,
          perPageSchedule,
          currentPageSchedule
        );
        // for (var i = 0; i < paymentScheduleData.value.length; i++) {
        //   var ele = paymentScheduleData.value[i];

        //   if (ele.is_paid == 1) {
        //     ele.is_enable_create_record = true;
        //   } else {
        //     ele.is_enable_create_record = true;
        //     last_enable_create_record.value = i + 1;
        //     break;
        //   }
        // }

        paymentScheduleData.value = paymentScheduleData.value.filter(
          (ele) => ele.display == 1
        );
      }
    );

    watch(
      () => [newContractData.value, selected_item.value],
      () => {
        // init
        cal_postpone_amount.value = 0;
        cal_defer_amount.value = 0;
        cal_this_period_amount.value = 0;
        calOwed.value = 0;

        if (newContractData.value) {
          const item = newContractData.value.payment_schedules.filter(
            (ele) => ele.id == selected_item.value
          )[0];
          // 選擇schedule
          target_schedule.value = { ...item };
          target_schedule.value.additional_fee = Math.round(
            Number(target_schedule.value.additional_fee)
          );
        }
      },
      { deep: true }
    );

    watch(
      () => target_schedule.value,
      (newVal) => {
        // 計算延期日
        // if (!newVal.postpone_date) {
        //   newVal.postpone_day = 0;
        //   tem_postpone_day.value = 0;
        // } else {
        //   const cal =
        //     new Date(newVal.postpone_date).getTime() -
        //     new Date(newVal.pay_date).getTime();
        //   let total = Math.ceil(cal / (1000 * 3600 * 24));
        //   if (total > 0) {
        //     newVal.postpone_day = total;
        //     tem_postpone_day.value = total;
        //   } else {
        //     newVal.postpone_day = 0;
        //     tem_postpone_day.value = 0;
        //   }
        // }
        // 計算延期金額
        if (newVal.postpone_day) {
          cal_postpone_amount.value =
            Number(newVal.postpone_day) * Number(newVal.postpone_fee);
        }

        // 遲交日期 最少日
        // var min;
        // if (newVal.postpone_date) {
        //   min = new Date(newVal.postpone_date);
        // } else {
        //   min = new Date(newVal.pay_date);
        // }
        // min.setDate(min.getDate() + 1);
        // defer_minDate.value = min.toISOString().split("T")[0];
        // if (
        //   new Date(defer_minDate.value).getTime() >
        //   new Date(newVal.defer_date).getTime()
        // ) {
        //   newVal.defer_date = null;
        // }

        // 計算遲交日
        // if (!newVal.defer_date) {
        //   newVal.defer_day = 0;
        //   tem_defer_day.value = 0;
        // } else {

        //   // 跟據 postpone_day
        //   if (newVal.postpone_date) {
        //     const cal =
        //       new Date(newVal.defer_date).getTime() -
        //       new Date(newVal.postpone_date).getTime();
        //     let total = Math.ceil(cal / (1000 * 3600 * 24));
        //     if (total > 0) {
        //       newVal.defer_day = total;
        //       tem_defer_day.value = total;
        //     } else {
        //       newVal.defer_day = 0;
        //       tem_defer_day.value = 0;
        //     }
        //   } else {
        //     // 跟據 pay_date
        //     const cal =
        //       new Date(newVal.defer_date).getTime() -
        //       new Date(newVal.pay_date).getTime();
        //     let total = Math.ceil(cal / (1000 * 3600 * 24));
        //     if (total > 0) {
        //       newVal.defer_day = total;
        //       tem_defer_day.value = total;
        //     } else {
        //       newVal.defer_day = 0;
        //       tem_defer_day.value = 0;
        //     }
        //   }
        // }

        // 計算遲交金額
        if (newVal.defer_day) {
          cal_defer_amount.value =
            Number(newVal.defer_day) * Number(newVal.defer_fee);
        }

        // 供款總計金額
        var additional_fee = newVal.additional_fee
          ? Math.round(Number(newVal.additional_fee))
          : 0;

        cal_this_period_amount.value = Math.round(
          Number(newVal.payment_amount) +
            cal_postpone_amount.value +
            cal_defer_amount.value +
            additional_fee
        );

        // 尚欠金額
        calOwed.value =
          cal_this_period_amount.value - Number(newVal.actual_paid_amount);

        calOwed.value = calOwed.value >= 0 ? calOwed.value : 0;

        // defalut 還款金額 -> 供款總計金額
        newPaymentData.value.amount = Math.round(Number(calOwed.value));

        // 是否Enable Create
        // newPaymentData.value.is_enable_create_record =
        //    newVal.is_enable_create_record ? true : false;
        //  newVal.hasOwnProperty("is_enable_create_record") &&

        // defalut 收款方式 -> 現金
        newPaymentData.value.receive_method_id = 1;

        // 生成還款詳情
        refetchTbData(
          "target_schedule",
          newVal.payments,
          paymentListData,
          totalRow,
          dataMeta,
          perPage,
          currentPage
        );

        totalRowSchedule.value = newContractData.value.payment_schedules.length;

        triggerKey.value = !triggerKey.value;
      },
      { deep: true }
    );

    const calPaymentOwed = (newVal) =>
      calOwed.value - Number(newVal) > 0 ? calOwed.value - Number(newVal) : 0;

    const todayDefaultDate = () => {
      const today = new Date(store.state.app.maxDate);
      return `${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDate()} ${today.getHours()}:${today.getMinutes()}`;
    };

    const loading = ref(false);
    const loadingRecord = ref(false);
    const loadingB = ref(false);
    const loadingC = ref(false);

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetPaymentScheduleData
    );

    const refFormObserverB = formValidation(resetPaymentData).refFormObserver,
      getValidationStateB = formValidation(resetPaymentData).getValidationState,
      resetFormB = formValidation(resetPaymentData).resetForm;

    const refFormObserverC =
        formValidation(resetPaymentDataEdit).refFormObserver,
      getValidationStateC =
        formValidation(resetPaymentDataEdit).getValidationState,
      resetFormC = formValidation(resetPaymentDataEdit).resetForm;

    const refPaymentsScheduleTable = ref(null);
    const paymentScheduleData = ref([]);
    const tableSchedulesColumns = [
      // { key: "id", label: "編號", sortable: true },
      { key: "period", label: "期數", sortable: true },
      { key: "pay_date", label: "供款日", sortable: true },
      { key: "initial_principal", label: "期初本金", sortable: true },
      { key: "principal", label: "每期本金", sortable: true },
      { key: "interest", label: "每期利息", sortable: true },
      { key: "payment_amount", label: "每期供款", sortable: true },
      { key: "remaining_principal", label: "尚欠本金", sortable: true },
      { key: "actual_amount", label: "應付金額", sortable: true },
      { key: "actual_paid_amount", label: "已付", sortable: true },
      // 供款金額 - 已償還金額 = 尚欠金額   (remaining_amount = actual_amount - actual_paid_amount)
      { key: "remaining_amount", label: "尚欠供款", sortable: true },
      { key: "postpone_date", label: "延期", sortable: true },
      { key: "postpone_remark", label: "延期備註", sortable: true },
      { key: "defer_date", label: "遲交", sortable: true },
      { key: "defer_remark", label: "遲交備註", sortable: true },
      { key: "additional_fee", label: "附加費", sortable: true },
      { key: "additional_remark", label: "附加備註", sortable: true },
    ];
    const perPageSchedule = ref(10);
    const totalRowSchedule = ref(0);
    const currentPageSchedule = ref(1);
    const perPageOptionsSchedule = [10, 25, 50, 100];
    const searchQuerySchedule = ref("");
    const sortBySchedule = ref("id");
    const isSortDirDescSchedule = ref(false);

    const dataMetaSchedule = computed(() => {
      const localItemsCount = refPaymentsScheduleTable.value
        ? refPaymentsScheduleTable.value.localItems.length
        : 0;
      return {
        from:
          perPageSchedule.value * (currentPageSchedule.value - 1) +
          (localItemsCount ? 1 : 0),
        to:
          perPageSchedule.value * (currentPageSchedule.value - 1) +
          localItemsCount,
        of: totalRowSchedule.value,
      };
    });

    const refRepaymentsListTable = ref(null);
    const paymentListData = ref([]);
    const tablePaymentsColumns = [
      { key: "id", label: "編號", sortable: true },
      { key: "payment_receipts", label: "收據文件", sortable: true },
      { key: "payment_documents", label: "其他文件", sortable: true },
      { key: "amount", label: "還款金額", sortable: true },
      { key: "payment_date", label: "還款日期", sortable: true },
      { key: "receive_method_id", label: "收款方式", sortable: true },
      { key: "receive_account_id", label: "收款帳號", sortable: true },
      { key: "receipt_number", label: "收據編號", sortable: true },
      { key: "receipt_date", label: "收據日期", sortable: true },
      { key: "remark", label: "備註", sortable: true },
      { key: "action", label: "行動", sortable: true },
    ];
    const perPage = ref(10);
    const totalRow = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(false);

    const dataMeta = computed(() => {
      const localItemsCount = refRepaymentsListTable.value
        ? refRepaymentsListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalRow.value,
      };
    });

    const refetchTbData = (
      key,
      target,
      data,
      total,
      meta,
      perPage,
      current
    ) => {
      total.value = target.length;
      const min = Number(meta.value.from - 1);
      const max = Number(perPage.value * current.value - 1);
      data.value = target.filter((ele, index) => index >= min && index <= max);
    };

    watch([currentPageSchedule, perPageSchedule, searchQuerySchedule], () => {
      // whole schedule
      refetchTbData(
        "payment_schedules",
        newContractData.value.payment_schedules,
        paymentScheduleData,
        totalRowSchedule,
        dataMetaSchedule,
        perPageSchedule,
        currentPageSchedule
      );
    });

    watch([currentPage, perPage, searchQuery], () => {
      // 還款詳情
      refetchTbData(
        "target_schedule",
        target_schedule.value.payments,
        paymentListData,
        totalRow,
        dataMeta,
        perPage,
        currentPage
      );
    });
    const is_oca = ref(false);
    const is_bad_debt = ref(false);


    const onOCAStatusChange = () =>{
      if(is_oca.value)
        is_bad_debt.value = false
      updatePaymentStatus();
    }

    const onBadDebtStatusChange = () =>{
      if(is_bad_debt.value)
        is_oca.value = false
      updatePaymentStatus();
    }
    
    const updatePaymentStatus = () => {
      store
        .dispatch("repayment/updatePaymentStatus", {
          id: newContractData.value.id,
          is_oca: is_oca.value,
          is_bad_debt: is_bad_debt.value,
        })
        .then((response) => {
          loading.value = false;
          Swal.fire({
            text: response.data.message,
            icon: "success",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          emit("refetch-data");
        })
        .catch((error) => {
          loading.value = false;
          Swal.fire({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    };

    const payOffDisableCreate = () => {
      return newContractData.value.remain_balance == 0;
    };
    const payOffDisableEdit = () => {
      return (
        newContractData.value.remain_balance == 0 &&
        oldPaymentDataEdit.value.is_early_repayment == 0
      );
    };

    onMounted(() => {
      selected_item.value = router.currentRoute.params.id;
      newPaymentData.value.receipt_date = todayDefaultDate();
      newPaymentData.value.payment_date = todayDefaultDate();
    });

    return {
      refPaymentsScheduleTable,
      tableSchedulesColumns,
      perPageSchedule,
      totalRowSchedule,
      currentPageSchedule,
      perPageOptionsSchedule,
      searchQuerySchedule,
      sortBySchedule,
      isSortDirDescSchedule,
      dataMetaSchedule,
      paymentScheduleData,

      refRepaymentsListTable,
      tablePaymentsColumns,
      perPage,
      totalRow,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      dataMeta,
      paymentListData,

      refFormObserver,
      refFormObserverB,
      refFormObserverC,
      getValidationState,
      getValidationStateB,
      getValidationStateC,
      resetForm,
      resetFormB,
      resetFormC,
      loading,
      loadingRecord,
      loadingB,
      loadingC,

      newContractData,
      newPaymentScheduleData,
      newPaymentData,
      oldPaymentDataEdit,

      getRowClass,
      selected_item,
      target_schedule,
      cal_postpone_amount,
      cal_defer_amount,
      cal_this_period_amount,
      calOwed,
      calPaymentOwed,
      todayDefaultDate,
      defer_minDate,
      tem_postpone_day,
      tem_defer_day,

      payOffDisableCreate,
      payOffDisableEdit,

      is_oca,
      is_bad_debt,
      onOCAStatusChange,
      onBadDebtStatusChange,

      // last_enable_create_record,
      triggerKey,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";
.info-wrap.style-2 {
  .info-item {
    margin-right: 3rem;
  }
}

.receive_payment {
  .item {
    width: 100%;
    .row {
      margin: 0px;
      .col-md-6 {
        padding: 0.5rem 0.75rem;
      }
    }
  }
}
.amount-info {
  max-width: 500px;
  width: 100%;
}
.detail_table {
  .b-table-details {
    td {
      text-align: left;
      background: #fdf7e7;
    }
  }
}
.payment_schecule_table {
  tr {
    border: 0px solid #fff;
    &.high-light-row {
      box-shadow: inset 0px 0px 5px #2f31ff;
      border: 0px;
    }
    td {
      padding: 0 1.5rem;
      height: 3.2rem;
    }
  }
}

.item-4-group,
.item-6-group {
  .item {
    width: 100%;
  }
}

.cal-input-group {
  display: flex;
  align-items: center;
  .content-wrapper {
    margin: 0 -5px;
  }
  .input1,
  .input2 {
    flex-wrap: nowrap;
    width: 47%;
  }
  .operator {
    width: 6%;
    text-align: center;
  }
}

@media screen and (min-width: 1401px) {
  .info-item-wrapper {
    width: 50%;
  }
  .item-4-group {
    .item {
      width: 33.3%;
      padding: 0 0.5rem;
    }
  }
  .item-6-group {
    .item {
      width: 50%;
      padding: 0 1rem;
    }
  }
}

@media screen and (max-width: 1400px) {
  .info-wrap {
    .info-item {
      margin-bottom: 1rem;

      &-label {
        min-width: 0px;
      }
    }
  }
}
</style>