<template>
  <b-modal
    id="modal-contract-status"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    scrollable
    title=""
    title-class="font-weight-bolder"
    body-class="common_modal modal_visible"
    hide-header
    hide-footer
    no-close-on-backdrop
    size="sm"
    hide-header-close
  >
    <div style="height:250px;">
      <h3 class="mt-1 mb-2 d-flex justify-content-center align-items-center">
        <font-awesome-icon class="mr-50" icon="fa-solid fa-filter" />
        合約狀態
      </h3>
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="onSubmit">
          <b-row>
            <b-col class="mb-1" cols="12">
              <b-form-group
                label="合約狀態："
                label-for="contract_status"
              >
                <v-select
                  id="contract_status"
                  v-model="new_contract_status_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="contractStatusesOptions"
                  label="name"
                  :reduce="(option) => option.id"
                  placeholder="選擇合約狀態"
                >
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="d-flex mt-3 align-items-center justify-content-center">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              class="mr-2"
              @click="hide"
            >
              <feather-icon size="16" class="mr-50" icon="XIcon" />
              取消
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
            >
              <feather-icon size="16" class="mr-50" icon="CheckCircleIcon" />
              確認
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ref, watch} from "@vue/composition-api";
import store from "@/store";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCardBody,
    BCard,
    BFormInvalidFeedback,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-contract-status");
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("repayment/editContractStatus", {id: this.contract_id ,contract_status_id:this.new_contract_status_id})
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit('refetch-data')        
              this.hide();       
            })
            .catch((error) => {
              this.loading = false;
              console.log(error)
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  props: {
    contract_status_id:{
    },
    contract_id:{
    },
    contractStatusesOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  setup(props){
    const new_contract_status_id = ref(1);

    watch(
      () => props.contract_status_id,
      (newVal) => {
        new_contract_status_id.value = newVal;
      }, {immediate:true}
    );

    return {
      new_contract_status_id
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
