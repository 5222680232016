import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRepayments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/payment_schedule/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // delete？
    // fetchRepayment(ctx, { id }) {
    //   return new Promise((resolve, reject) => {
    //     axiosIns
    //       .get(`/payment_schedule/?id=${id}`)
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },
    editRepayment(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/payment_schedule/", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteRepayment(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/payment_schedule/", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchReceiveAccounts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/receive_account/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    // delete？
    // fetchPayments(ctx, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     axiosIns
    //       .get("/payment/list", { params: queryParams })
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },

    // delete？
    // fetchPayment(ctx, { id }) {
    //   return new Promise((resolve, reject) => {
    //     axiosIns
    //       .get(`/payment/?id=${id}`)
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },
    editPayment(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/payment/", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deletePayment(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/payment/", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // delete？
    // fetchContracts(ctx, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     axiosIns
    //       .get("/contract/list", { params: queryParams })
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },
    fetchContract(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/contract/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editContractStatus(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/contract/contract_status", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportPaymentReceipt(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/payment/receipt", { params: queryParams, responseType: "blob" })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // delete？
    // editPaymentDocument(ctx, data) {
    //   return new Promise((resolve, reject) => {
    //     axiosIns
    //       .post("/payment_document/", data)
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },
    deletePaymentDocument(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/payment_receipt", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deletePaymentOtherDocument(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/payment_document", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getEarlyRepaymentInfo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/contract/early_repayment/info", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updatePaymentStatus(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .patch("/contract/status", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  }
};
